<template>
  <panel :title="$tc('profile.change_password')">
    <el-alert
      v-if="error"
        :title="$tc('general.ops')"
      :description="error"
      type="error"
      class="mb-15"
      :closable="false"
      show-icon>
    </el-alert>

    <el-form class="width small">
      <MainInput
        v-model="form.current"
        :validator="$v.form.current"
        prefix-icon="el-icon-key"
        :label="$tc('profile.current_password')"
        show-password />

      <MainInput
        v-model="form.password"
        :validator="$v.form.password"
        prefix-icon="el-icon-key"
        :label="$tc('profile.new_password')"
        show-password />

      <div class="center">
        <el-button
          type="primary"
          class="mt-15"
          :loading="loading"
          @click="save()">
          {{ $tc('general.save') }}
        </el-button>
      </div>
    </el-form>
  </panel>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

import MainInput from '@/components/forms/Input.vue'

export default {
  components: {
    MainInput
  },

  data () {
    return {
      form: {
        current: null,
        password: null
      },

      error: null,
      loading: false
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('profile.change_password'),
      breadcrumbs: [
        {
          text: this.$tc('profile.my_profile')
        },
        {
          text: this.$tc('profile.change_password')
        }
      ]
    })
  },

  methods: {
    async save () {
      this.$v.$touch()
      this.error = null
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('auth/changePassword', this.form)
        this.form.current = null
        this.form.password = null
        this.$message.success(this.$tc('messages.success_changed_password'))
        this.$v.$reset()
      } catch (error) {
        this.error = error.response?.data?.error || this.$defaultErrorMessage
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      current: { required },
      password: { required, minLength: minLength(6) }
    }
  }
}
</script>
