<template>
  <div v-if="category && discipline">
    <h3>{{ category.name }}</h3>
    <h4>{{ discipline.name }} ({{ data.length }})</h4>
    <table>
      <thead>
        <tr>
          <th>{{ $tc('general.n0') }}</th>
          <th v-if="!isTeam">{{ $tc('form.image') }}</th>
          <th>{{ $tc('form.name') }}</th>
          <th v-if="!isTeam">{{ $tc('form.belt') }}</th>
          <th v-if="!isTeam">{{ $tc('form.date_of_birth') }}</th>
          <th>{{ $tc('club.short_name') }}</th>
        </tr>

      </thead>
      <tbody>
        <tr v-for="(registration, index) in data" :key="registration.id">
          <td>{{ index + 1 }}.</td>
          <td v-if="!isTeam" style="width: 80px">
            <div class="image">
              <img :src="$file('avatars', registration.competitor.avatar)" style="width: 75px; height: 75px; object-fit: cover;" class="image" />
            </div>
          </td>
          <td>
            {{ getCompetitorNames(registration, registration.assignedNumber)  }}
          </td>
          <td v-if="!isTeam">{{ registration.competitor.belt | belt }}</td>
          <td v-if="!isTeam">{{ registration.competitor.born | date }}</td>
          <td>{{ registration.club.shortName }}, {{ registration.club.city }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getCompetitors, getCompetitorNames } from './tournament-discipline'

export default {
  props: {
    category: {
      type: Object,
      required: true
    },

    discipline: {
      type: Object,
      required: true
    },

    competitors: {
      type: Array,
      required: true
    },

    isTeam: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    data () {
      if (!this.category || !this.discipline) return []
      return getCompetitors(this.category.id, this.discipline.id, this.competitors)
    }
  },

  methods: {
    getCompetitorNames
  }
}
</script>
