<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <div class="el-input">
      <div class="el-input__inner">
        <input
          v-show="!data"
          ref="upload"
          type="file"
          :disabled="disabled"
          :multiple="multiple"
          @blur="$emit('blur')"
          @change="uploadFile($event)">

        <div
          v-if="data"
          class="selected-file">
          <div v-if="previewImage" class="preview-image">
            <img :src="previewImage">
          </div>
          <span class="text" :class="{ padding: previewImage }">{{ selectedText }}</span>

          <div class="icons">
            <i
              class="el-icon-upload2"
              @click="reupload()" />
            <i
              class="el-icon-delete"
              @click="clear()" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="data && multiple" class="files">
      <file
        v-for="(file, index) in data"
        :key="file.id"
        :file="file"
        class="file"
        deletable
        small
        @delete="removeData(index)" />
    </div>
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'
import helperMixin from '../helper-mixin.js'

import File from '@/components/files/File'

export default {
  mixins: [inputMixin, helperMixin],

  components: {
    File
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    },

    onlyImages: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      data: null
    }
  },

  computed: {
    selectedText () {
      if (this.multiple) return this.data?.map(file => file.name).join(', ')

      return this.data?.name
    },

    previewImage () {
      if (!this.data || this.multiple) return null
      return URL.createObjectURL(this.data)
    }
  },

  watch: {
    value (value) {
      if (!value) this.data = null
    }
  },

  created () {
    if (this.value) this.data = this.value
  },

  methods: {
    reupload () {
      this.$refs.upload.click()
    },

    removeData (index) {
      this.data.splice(index, 1)
    },

    clear () {
      this.data = null
      this.$refs.upload.value = null
      this.$emit('input', this.data)
    },

    uploadFile ($e) {
      const files = $e?.target?.files
      if (!files.length) return

      if (this.onlyImages && files.some(f => !this.isImage(f))) {
        this.$message.error('Only image files are allowed. (.jpg, .jpeg, .png, .gif)')
        this.clear()
        return
      }

      if (this.data && this.multiple) {
        this.data.push(...files)
      } else {
        this.data = this.multiple ? [...files] : files[0]
      }

      this.$emit('input', this.data)
      this.$refs.upload.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-file {
  display: flex;
  justify-content: space-between;
  .preview-image {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    position: absolute;
    left: 5px;
    top: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    overflow: hidden;
    flex-shrink: 1;
    display: block;
    width: 100%;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.padding {
      padding-left: 25px;
    }
  }
}

.icons {
  white-space: nowrap;
  font-size: 18px;
  i {
    cursor: pointer;
    &:hover {
      color: #409EFF;
    }
    &:last-of-type {
      margin-left: 5px;
    }
  }
}

.files {
  width: 100%;
  margin-top: 10px;
  .file {
    width: 100%;
    display: flex;
  }
  .file + .file {
    margin-top: 5px;
  }
}
</style>
