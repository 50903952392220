<template>
  <panel ref="panel" :title="`${title} (${teams.length})`" :loader="loader" collapsable>
    <template v-slot:actions>
      <el-button
        v-if="!hideAdd && (user.isAdmin || (tournament.isActive && canEdit))"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManage = true">
        {{ $tc('tournament.register_team') }}
      </el-button>

      <template v-if="teams.length">
        <el-tooltip :content="$tc('general.registration_fee')" placement="top">
          <el-button
            icon="el-icon-tickets"
            size="medium"
            circle
            @click="$emit('registration-fee')" />
        </el-tooltip>

        <el-tooltip :content="$tc('general.print')" placement="top">
          <el-button
            icon="el-icon-printer"
            size="medium"
            circle
            @click="openPrint(club ? club.name : title, $refs.printTable ? $refs.printTable.$el : null)" />
        </el-tooltip>

        <el-dropdown v-if="user.isAdmin" trigger="click" @command="setSortBy">
          <el-tooltip :content="$tc('general.order_by')" placement="top">
            <el-button
              icon="el-icon-sort"
              size="medium"
              class="ml-10"
              circle />
          </el-tooltip>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="sortBy === 'DESC'" command="DESC">{{ $tc('general.latest_first') }}</el-dropdown-item>
            <el-dropdown-item :disabled="sortBy === 'ASC'" command="ASC">{{ $tc('general.oldest_first') }}</el-dropdown-item>
            <el-dropdown-item :disabled="sortBy === 'CLUB'" command="CLUB">{{ $tc('general.by_club') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </template>

    <teams-table :data="sortedTeams" @remove="updateTournament($event)" @edit="openEdit($event)" :can-edit="canEdit" :show-club-name="showClubName" />

    <print-teams-table ref="printTable" v-show="false" :data="sortedTeams" :show-club-name="showClubName" />

    <manage-team
      v-if="openManage"
      :club="club"
      :tournament="tournament"
      :team="editing"
      @edit="updateTournament($event)"
      @add="updateTournament($event)"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'
import { registrationOrderBy } from '@/common/util'

import ManageTeam from './ManageTeam'
import TeamsTable from './TeamsTable'
import PrintTeamsTable from './PrintTeamsTable'

export default {
  components: {
    TeamsTable,
    ManageTeam,
    PrintTeamsTable
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    club: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      default: 'Prijavljene ekipe'
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    teams: {
      type: Array,
      required: true
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    loader: {
      type: Boolean,
      default: false
    },

    hideAdd: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      editing: null,
      openManage: false,
      openPrint,
      sortBy: 'DESC'
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    sortedTeams () {
      return registrationOrderBy(this.teams, this.sortBy)
    }
  },

  created () {
    this.sortBy = localStorage.getItem('teamsSortBy') || 'DESC'
  },

  methods: {
    setSortBy (sortBy) {
      localStorage.setItem('teamsSortBy', sortBy)
      this.sortBy = sortBy
    },

    updateTournament (tournament) {
      this.$store.commit('tournaments/setSingle', tournament)
      if (tournament.isActive) {
        this.$store.commit('app/setActiveTournament', tournament)
      }
      this.close()
    },

    openEdit (team) {
      this.editing = team
      this.openManage = true
    },

    close () {
      this.editing = null
      this.openManage = false
    }
  }
}
</script>
