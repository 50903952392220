<template>
  <main-drawer title="Izmeni udruženje" :size="500" @close="$emit('close')">
    <main-input v-model="form.name" :validator="$v.form.name" :label="$tc('club.club_name')" />
    <main-input v-model="form.shortName" :validator="$v.form.shortName" :label="$tc('club.short_name')" />
    <main-input v-model="form.city" :validator="$v.form.city" :label="$tc('form.city')" />
    <main-input v-model="form.address" :validator="$v.form.address" :label="$tc('form.address')" />
    <main-input v-model="form.email" :validator="$v.form.email" :label="$tc('form.email')" />
    <main-input v-model="form.website" :validator="$v.form.website"  :label="$tc('form.website')" />
    <main-input v-model="form.contact" :validator="$v.form.contact" :label="$tc('form.contact_person')" />
    <main-input v-model="form.mobile" :validator="$v.form.mobile" :label="$tc('form.mobile')" />
    <main-input
      v-if="user.isAdmin"
      v-model.number="form.costReduction"
      label="Umanjenje kotizacije"
      append="%" />
    <main-upload-input v-model="logo" label="Logo"/>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">Otkaži</el-button>
        <el-button type="primary" @click="save()">Izmeni</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required, email } from 'vuelidate/lib/validators'

export default {

  props: {
    club: {
      type: Object,
      required: true
    }

  },
  data () {
    return {
      form: {
        name: null,
        shortName: null,
        city: null,
        address: null,
        email: null,
        website: null,
        contact: null,
        mobile: null,
        costReduction: 0
      },

      logo: null
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  created () {
    this.form = {
      name: this.club.name,
      shortName: this.club.shortName,
      city: this.club.city,
      address: this.club.address,
      email: this.club.email,
      website: this.club.website,
      contact: this.club.contact,
      mobile: this.club.mobile,
      costReduction: this.club.costReduction
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true

      const payload = { ...this.form }

      if (!this.user.isAdmin) {
        delete payload.costReduction
      }

      try {
        let club = await this.$store.dispatch('clubs/update', {
          id: this.club.id,
          payload
        })

        if (this.logo) {
          club = await this.$store.dispatch('clubs/upload', {
            id: club.id,
            file: this.logo
          })
        }

        this.$message.success('Uspešno ste izmenili udruženje.')
        this.$emit('edit', club)
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      name: { required },
      shortName: { required },
      city: { required },
      address: { required },
      email: { required, email },
      contact: { required },
      mobile: { required }
    }
  }
}
</script>
