<template>
  <el-table
    :data="data"
    style="width: 100%">
    <el-table-column
      :label="$tc('form.name')">
      <template slot-scope="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>

    <el-table-column
      :label="$tc('general.registration_fee')"
      width="200px"
      align="center">
      <template slot-scope="scope">
        <i :class="scope.row.noPay ? 'el-icon-error' : 'el-icon-success'" />
      </template>
    </el-table-column>

    <el-table-column
      v-if="canEdit"
      :label="$tc('general.actions')"
      width="80px"
      align="right">
      <template slot-scope="scope">
        <el-dropdown trigger="click" @command="(command) => handleCommand(command, scope.row)">
          <el-button type="primary" icon="el-icon-more" circle />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit"><i class="el-icon-edit" />{{ $tc('general.edit') }}</el-dropdown-item>
              <el-dropdown-item command="archive"><i class="el-icon-takeaway-box" />{{ scope.row.isArchived ? $tc('general.unarchive') : $tc('general.archive', 2) }}</el-dropdown-item>
            <el-dropdown-item command="delete"><i class="el-icon-delete" />{{ $tc('general.delete') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    handleCommand (command, item) {
      if (command === 'edit') return this.$emit('edit', item)
      if (command === 'archive') return this.askArchive(item)
      if (command === 'delete') return this.beforeRemove(item)
    },

    async askArchive (item) {
      try {
        await this.$confirm(this.$tc(item.isArchived ? 'messages.ask_unarchive_category' : 'messages.ask_archive_category'))

        this.doArchive(item, !item.isArchived)
      } catch {}
    },

    async doArchive (item, isArchived = true) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('categories/update', {
          id: item.id,
          payload: { isArchived }
        })
        this.$message.success(this.$tc(isArchived ? 'messages.success_archived_category' : 'messages.success_unarchived_category'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    async beforeRemove (category) {
      try {
        await this.$confirm(`${this.$tc('messages.ask_remove_category')}: ${category.name}`)

        this.onDelete(category)
      } catch {}
    },

    async onDelete (category) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('categories/delete', category.id)
        this.$message.success(this.$tc('messages.success_remove_category'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-icon-error {
    color: $danger;
  }
  .el-icon-success {
    color: $success;
  }
</style>
