<template>
  <div class="page">
    <img
      src="@/assets/images/login.svg"
      class="image">

    <panel class="panel">
      <h1>{{ $tc('auth.reset_password') }}</h1>
      <el-form
        :model="form"
        label-width="0">
        <main-input v-model="form.password" type="password" :label="$tc('form.password')" :validator="$v.form.password" show-password />
        <main-input v-model="form.repeat_password" type="password" :label="$tc('form.repeat_password')" :validator="$v.form.repeat_password" show-password />
      </el-form>

      <el-button
        class="button"
        type="primary"
        :loading="loading"
        round
        @click="login()">
        {{ $tc('auth.reset_password') }}
      </el-button>

      <router-link
        :to="{ name: 'Register' }"
        class="link">
        {{ $tc('auth.no_account_register') }}
      </router-link>
    </panel>
  </div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      loading: false,
      form: {
        password: null,
        repeat_password: null
      }
    }
  },

  computed: {
    token () {
      return this.$route.params.token
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('auth.reset_password')
    })
  },

  methods: {
    async login () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('auth/resetPassword', {
          token: this.token,
          password: this.form.password
        })
        this.$router.push({ name: 'Login' })
        this.$message.success(this.$tc('messages.success_reset_password'))
      } catch (error) {
        this.$message.error(this.$tc('messages.error_unknown'))
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      repeat_password: { required, sameAsPassword: sameAs('password') }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .image {
      width: 500px;
      margin-right: 150px;
    }
    .panel {
      width: 500px;
      padding: 30px 50px;

      .button {
        margin-top: 30px;
        width: 100%;
        height: 50px;
        border-radius: 9999px;
      }
      .link {
        display: block;
        text-align: center;
        margin-top: 20px;
        font-size: 17px;
        color: $dark;
      }
    }
  }

   @media (max-width: 600px) {
      .page .image {
        margin: 0;
        width: 100%;
      }
    }
</style>
