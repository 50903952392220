<template>
  <div class="large">
    <el-row :gutter="20">
      <el-col :span="6">
        <side-menu :data="navigation" />
      </el-col>
      <el-col :span="18">
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navigation: [
        {
          title: this.$tc('profile.profile_settings'),
          to: { name: 'ManageProfile' }
        },
        {
          title: this.$tc('profile.change_password'),
          to: { name: 'ChangePassword' }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
 @media (min-width: 1050px) {
    .panel {
      width: 100% !important;
    }
  }
@media (max-width: 730px) {
  .el-row {
    display: grid;

    .el-col {
      width: 100% !important;

      &:nth-child(2) {
        margin-top: 30px;
      }
    }
  }
}
</style>
