import i18n from '@/i18n'
import config from '@/config'

const refereeDanTemp = {}
for (let i = 1; i <= 10; i++) {
  refereeDanTemp[i] = `${i}. dan`
}

export const sexOptions = [
  {
    id: true,
    text: i18n.tc('general.male')
  },
  {
    id: false,
    text: i18n.tc('general.female')
  }
]

export const teamTypes = {
  MEN: i18n.tc('general.male'),
  WOMEN: i18n.tc('general.female'),
  MIX: i18n.tc('general.mixed')
}

export const teamTypeOptions = Object.keys(teamTypes).map(key => ({
  id: key,
  text: teamTypes[key]
}))

export const belts = config.isWff ? {
  BLACK: 'Black',
  BROWN: 'Brown',
  PURPLE: 'Purple',
  BLUE: 'Blue',
  GREEN: 'Green',
  ORANGE: 'Orange',
  YELLOW: 'Yellow',
  WHITE: 'White'
} : {
  CRNI: 'Crni',
  BRAON: 'Braon',
  LJUBICASTI: 'Ljubičasti',
  PLAVI: 'Plavi',
  ZELENI: 'Zeleni',
  CRVENI: 'Crveni',
  ORANZ: 'Oranž',
  ZUTI: 'Žuti',
  BELI: 'Beli'
}

export const beltOptions = Object.keys(belts).map(key => ({
  id: key,
  text: belts[key]
}))

export const refereeRangs = {
  NATIONAL_A: i18n.tc('referee_rangs.NATIONAL_A'),
  NATIONAL_B: i18n.tc('referee_rangs.NATIONAL_B'),
  NATIONAL_C: i18n.tc('referee_rangs.NATIONAL_C'),
  NATIONAL_D: i18n.tc('referee_rangs.NATIONAL_D'),
  INTEARNATIONAL_A: i18n.tc('referee_rangs.INTEARNATIONAL_A'),
  INTEARNATIONAL_B: i18n.tc('referee_rangs.INTEARNATIONAL_B'),
  INTEARNATIONAL_C: i18n.tc('referee_rangs.INTEARNATIONAL_C'),
  INTEARNATIONAL_D: i18n.tc('referee_rangs.INTEARNATIONAL_D'),
  TRAINEE: i18n.tc('referee_rangs.TRAINEE'),
  ZAPISNICAR: i18n.tc('referee_rangs.ZAPISNICAR'),
  OTHER: i18n.tc('referee_rangs.OTHER')
}

export const refereeRangOptions = Object.keys(refereeRangs).map(key => ({
  id: key,
  text: refereeRangs[key]
}))

export const refereeDans = refereeDanTemp

export const refereeDanOptions = Object.keys(refereeDanTemp).map(key => ({
  id: key,
  text: refereeDanTemp[key]
}))
