import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import auth from './auth'
import clubs from './clubs'
import members from './members'
import coaches from './coaches'
import referees from './referees'
import settings from './settings'
import documents from './documents'
import officials from './officials'
import categories from './categories'
import disciplines from './disciplines'
import tournaments from './tournaments'
import tournamentImages from './tournamentImages'
import disciplineCategories from './disciplineCategories'
import tournamentDisciplines from './tournamentDisciplines'
import tournamentTeamDisciplines from './tournamentTeamDisciplines'
import factory from './factory'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    users: factory('User'),
    auth,
    clubs,
    coaches,
    members,
    settings,
    referees,
    documents,
    officials,
    categories,
    disciplines,
    tournaments,
    tournamentImages,
    disciplineCategories,
    tournamentDisciplines,
    tournamentTeamDisciplines
  }
})

export default store
