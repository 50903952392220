<template>
  <div>
    <header class="nav-mobile">
      <div class="info">
        <main-image :src="$file('settings', this.globalSettings.logo)" style="width: 60px; height: 60px;" />
        <span class="name">{{ globalSettings.title }}</span>
      </div>

      <div>
        <el-button
          icon="el-icon-menu"
          size="large"
          @click="showMenu()"
        />
      </div>
    </header>

    <aside :class="this.showMobileMenu && 'active'">
      <nav>
        <el-button
          class="close-nav"
          icon="el-icon-close"
          size="large"
          link
          @click="closeMenu()"
        />

        <div id="logo" @click="closeMenu()">
          <main-image :src="$file('settings', this.globalSettings.logo)" class="logo-image" />
        </div>

        <div class="info">
          <span class="name">{{ globalSettings.title }}</span>
        </div>

        <ul>
          <li
            @click="closeMenu()"
            v-for="item, index in menu"
            :key="index">
            <template v-if="item.divider">
              <div class="line" />
            </template>
            <template v-else-if="item.section">
              <div class="section-title">{{ item.section }}</div>
            </template>
            <a v-else-if="item.onClick" @click="item.onClick()">
              <div class="indicator" />
              <i :class="[item.icon, 'icon']" />
              <span>{{ item.title }}</span>
            </a>
            <router-link v-else :to="item.to">
              <div class="indicator" />
              <i :class="[item.icon, 'icon']" />
              <span>{{ item.title }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: null,
      showMobileMenu: false
    }
  },

  computed: {
    globalSettings () {
      return this.$store.state.app.settings
    },

    currentTournament () {
      return this.$store.state.app.activeTournament
    },

    club () {
      return this.user.club
    },

    menu () {
      const routes = [
        // {
        //   title: 'Početna',
        //   icon: 'el-icon-house',
        //   to: { name: 'Dashboard' }
        // }
      ]

      if (this.user.club) {
        routes.push({
          title: this.$tc('menu.my_club'),
          icon: 'el-icon-tickets',
          to: { name: 'MyClub' }
        })
      }

      if (!this.$feature.isWff) {
        routes.push({
          title: this.$tc('general.club', 2),
          icon: 'el-icon-document-copy',
          to: { name: 'ClubsMain' }
        })
      }

      if (this.currentTournament) {
        routes.push({
          title: this.currentTournament.isActive ? this.$tc('menu.open_tournament') : this.$tc('menu.last_tournament'),
          icon: 'el-icon-trophy',
          to: { name: 'ActiveTournament' }
        })
      }

      if (this.user.isAdmin) {
        routes.push(
          { divider: true },
          { section: this.$tc('menu.admin_panel') },
          {
            title: this.$tc('tournament.tournament', 2),
            icon: 'el-icon-files',
            to: { name: 'Tournaments' }
          },
          // {
          //   title: this.$tc('menu.users'),
          //   icon: 'el-icon-user',
          //   to: { name: 'Users' }
          // },
          {
            title: this.$tc('settings.settings'),
            icon: 'el-icon-set-up',
            to: { name: 'Settings' }
          })
      }

      routes.push(
        { divider: true },
        { section: this.$tc('profile.my_profile') },
        {
          title: this.$tc('profile.profile_settings'),
          icon: 'el-icon-setting',
          to: { name: 'ManageProfileParent' }
        },
        {
          title: this.$tc('auth.log_out'),
          icon: 'el-icon-switch-button',
          onClick: this.logout
        }
      )

      return routes
    },

    user () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    onClick (action) {
      this[action]()
    },

    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login' })
    },

    manageProfile () {
      if (this.$route.name === 'ManageProfile') return
      this.$router.push({ name: 'ManageProfile' })
    },

    changePassword () {
      if (this.$route.name === 'ChangePassword') return
      this.$router.push({ name: 'ChangePassword' })
    },

    settings () {
      if (this.$route.name === 'ProfileSettings') return
      this.$router.push({ name: 'ProfileSettings' })
    },

    showMenu () {
      this.showMobileMenu = !this.showMobileMenu
    },

    closeMenu () {
      this.showMobileMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>
aside {
  width: 350px;
  height: 100%;
  padding: 30px 0 30px 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  box-sizing: border-box;
  transition: 1s;

  nav {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    padding: 50px 0px;
    box-sizing: border-box;
    overflow-y: auto;

    #logo {
      width: 119px;
      height: 119px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 999px;
      }
    }
    .info {
      margin-top: 10px;
      text-align: center;
      .name {
        display: block;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
      .member-status {
        display: inline-block;
        margin: 10px auto;
        color: $secondary2;
        height: 34px;
        line-height: 34px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 99px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 40px);
        box-sizing: border-box;
        img {
          vertical-align: middle;
        }
      }
    }
    ul {
      margin: 50px 0 0 0;
      padding: 0;
      list-style: none;
      li {
        .line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $border;
          margin: 10px 0;
        }
        .section-title {
          font-size: 12px;
          text-transform: uppercase;
          padding-left: 40px;
          line-height: 40px;
          color: $text;
        }
        a {
          display: flex;
          align-items: center;
          height: 50px;
          color: $secondary2;
          .indicator {
            opacity: 0;
            vertical-align: middle;
            width: 6px;
            height: 100%;
            border-top-right-radius: 99px;
            border-bottom-right-radius: 99px;
            background-color: $primary;
            transition: all ease .2s;
          }
          .icon {
            display: inline-block;
            width: 20px;
            font-size: 20px;
            margin-left: 40px;
            margin-right: 10px;
          }
          span {
            font-size: 15px;
            font-weight: 500;
          }
          &:hover, &:focus {
            cursor: pointer;
            color: $secondary;
          }
          &.router-link-active {
            .indicator {
              opacity: 1;
            }
            color: $primary;
          }
        }
      }
    }
  }
}
@media (min-width: 1150px) {
  .nav-mobile {
    display: none;
  }

   .close-nav {
    display: none;
   }
}

@media (max-width: 1150px) {
  .panel .content {
    padding: 0;
  }
  aside nav{
    border-radius: 0;
  }

  .nav-mobile {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .info {
      display: flex;
      align-content: center;
      .name {
        line-height: 60px;
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .close-nav {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px;
  }
  aside {
    position: fixed;
    left: -100%;
    width: 100%;
    padding: 0;
  }

  aside.active {
    left: 0;
  }
  main {
    padding-left: 30px;
  }
}
</style>
