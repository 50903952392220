<template>
  <panel :title="$tc('settings.general_settings')">
    <main-input v-model="form.title" :label="$tc('settings.website_name')" :validator="$v.form.title"/>
    <main-textarea v-model="form.description" :label="$tc('settings.website_description')" :validator="$v.form.description"/>
    <main-upload-input v-model="logo" :label="$tc('settings.logo')"/>

    <template v-slot:footer>
      <div class="flex-end">
        <el-button type="primary" :loading="loading" @click="save()">{{ $tc('general.save') }}</el-button>
      </div>
    </template>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },

    members: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      form: {
        title: null,
        description: null
      },

      logo: null,

      loading: false
    }
  },

  computed: {
    settings () {
      return this.$store.state.app.settings
    }
  },

  created () {
    this.form = {
      title: this.settings.title,
      description: this.settings.description
    }

    this.$store.commit('app/set', {
      title: this.$tc('settings.general_settings'),
      breadcrumbs: [
        {
          text: this.$tc('settings.settings')
        },
        {
          text: this.$tc('settings.general_settings')
        }
      ]
    })
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('settings/update', {
          id: this.settings.id,
          payload: this.form
        })

        if (this.logo) {
          await this.$store.dispatch('settings/upload', {
            id: this.settings.id,
            file: this.logo
          })
        }

        this.$message.success(this.$tc('messages.settings_successfully_updated'))
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        title: { required },
        description: { required }
      }
    }
  }
}
</script>
