<template>
  <main-drawer :title="isEdit ? $tc('auth.edit_member') : $tc('auth.add_member')" @close="$emit('close')">
    <main-input v-model="form.firstName" :label="$tc('form.first_name')" :validator="$v.form.firstName"/>
    <main-input v-model="form.lastName" :label="$tc('form.last_name')" :validator="$v.form.lastName"/>
    <main-date-picker v-model="form.born" :label="$tc('form.date_of_birth')" :validator="$v.form.born"/>
    <main-select v-model="form.isMale" :label="$tc('form.gender')" :options="sexOptions" :validator="$v.form.isMale"/>
    <main-select v-model="form.belt" :label="$tc('form.belt')" :options="beltOptions" :validator="$v.form.belt"/>
    <template v-if="$feature.registration_fee">
      <main-checkbox v-if="!form.alwaysPays" v-model="form.noPay" :label="$tc('club.registration_fee_free')" />
      <main-checkbox v-if="user.isAdmin && !form.noPay" v-model="form.alwaysPays" :label="$tc('club.must_pay_registration_fee')" />
    </template>
    <main-upload-input v-model="image" :label="$tc('form.image')"/>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.add') }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import { sexOptions, beltOptions } from '@/common/definitions'
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    club: {
      type: Object,
      default: null
    },

    member: {
      type: Object,
      default: null
    },

    customAdd: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        born: null,
        isMale: null,
        belt: null,
        noPay: null,
        alwaysPays: null
      },

      sexOptions,
      beltOptions,
      image: null
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    isEdit () {
      return !!this.member
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        born: this.member.born,
        isMale: this.member.isMale,
        belt: this.member.belt,
        noPay: this.member.noPay,
        alwaysPays: this.member.alwaysPays
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      if (this.customAdd) return this.$emit('custom-add', this.form)

      this.loading = true
      try {
        let member
        if (this.isEdit) {
          member = await this.$store.dispatch('members/update', {
            id: this.member.id,
            payload: this.form
          })
        } else {
          member = await this.$store.dispatch('members/create', {
            clubId: this.club.id,
            ...this.form
          })
        }

        if (this.image) {
          member = await this.$store.dispatch('members/upload', {
            id: member.id,
            file: this.image
          })
        }

        this.$message.success(this.isEdit ? this.$tc('messages.success_updated_member') : this.$tc('messages.success_created_member'))
        this.$emit(this.isEdit ? 'edit' : 'add', member)
      } catch (error) {
        console.error(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        born: { required },
        isMale: { required },
        belt: { required }
      }
    }
  }
}
</script>
