<template>
  <div>
    <error-box v-if="error" :loading="loading" @reload="load()" />

    <loader v-else-if="loading" />

    <template v-if="!error && !loading">
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: false,
      loading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  watch: {
    user (user) {
      if (user) {
        this.$store.dispatch('categories/load')
        this.$store.dispatch('disciplines/load')
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('app/loadData')
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
