export default {
  methods: {
    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type)
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },

    isImage (file) {
      var parts = file.name.split('.')
      const ext = parts[parts.length - 1]
      const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(ext)
      if (!isImage) return false
      return isImage
    }
  }
}
