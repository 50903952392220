<template>
  <div class="large">
    <el-row :gutter="20">
      <el-col :span="6">
        <side-menu :data="navigation" />
      </el-col>
      <el-col :span="18">
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  computed: {
    navigation () {
      const data = [
        {
          title: this.$tc('settings.general_settings'),
          to: { name: 'AppSettings' }
        },
        {
          title: this.$tc('settings.category', 2),
          to: { name: 'CategorySettings' }
        },
        {
          title: this.$tc('settings.discipline', 2),
          to: { name: 'DisciplineSettings' }
        }
      ]

      if (this.$feature.registration_fee) {
        data.push({
          title: this.$tc('general.registration_fee'),
          to: { name: 'RegistrationFeeSettings' }
        })
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
 @media (min-width: 1050px) {
    .panel {
      width: 100% !important;
    }
  }
@media (max-width: 730px) {
  .el-row {
    display: grid;

    .el-col {
      width: 100% !important;

      &:nth-child(2) {
        margin-top: 30px;
      }
    }
  }
}
</style>
