<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        :label="$tc('general.n0')"
        width="50px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}.
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('tournament.competitors', 2)">
        <template slot-scope="scope">
          {{ getCompetitorNames(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="showClubName"
        :label="$tc('general.club')">
        <template slot-scope="scope">
          {{ scope.row.club.shortName }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('general.type')">
        <template slot-scope="scope">
          {{ scope.row.type | teamType }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('settings.discipline', 2)"
        align="right">
        <template slot-scope="scope">
          <data-list :list="getDisciplinesDataList(scope.row.categories)" />
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        align="right">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" circle @click="$emit('edit', scope.row)" />
          <el-button type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    showClubName: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({
          label: item.category?.name
        })

        const disciplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))

        acc.push(...disciplines)

        return acc
      }, [])
    },

    getCompetitorNames (item) {
      const keys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']

      const competitors = keys.map(key => item[key]).filter(item => !!item).map(competitor => `${competitor.firstName} ${competitor.lastName} (${competitor.isMale ? 'M' : 'Ž'})`)

      return competitors.join(', ')
    },

    async beforeRemove (registration) {
      try {
        await this.$confirm(`${this.$tc('messages.ask_remove_team')}: ${this.getCompetitorNames(registration)}`)
        this.onDelete(registration)
      } catch {}
    },

    async onDelete (registration) {
      const loading = this.$loading()
      try {
        const tournament = await this.$store.dispatch('tournaments/removeTeam', registration.id)
        this.$emit('remove', tournament)
        this.$message.success(this.$tc('messages.success_deleted_team'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
