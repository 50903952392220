<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>{{ $tc('form.image') }}</th>
          <th>{{ $tc('form.name') }}</th>
          <th v-if="showClubName">{{ $tc('club.club_name') }}</th>
          <template v-if="$config.isWff">
            <th>{{ $tc('form.no_certificate_wff') }}</th>
            <th>{{ $tc('form.no_referee_certificate_wff') }}</th>
            <th>{{ $tc('form.no_fudokan_passport') }}</th>
          </template>
          <th>{{ $tc('form.day') }}</th>
          <th>{{ $tc('club.referee_rang') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td style="width: 80px">
            <div class="image">
              <img :src="$file('avatars', item.avatar)" style="width: 75px; height: 75px; object-fit: cover;" class="image" />
            </div>
          </td>
          <td>{{ item.firstName }} {{ item.lastName }}</td>
          <td v-if="showClubName">{{ item.club ? item.club.shortName : '' }}</td>
          <template v-if="$config.isWff">
            <td>{{ item.noCertificateWff }}</td>
            <td>{{ item.noRefereeCertificateWff }}</td>
            <td>{{ item.noFudokanPassport }}</td>
          </template>
          <td>{{ item.dan | refereeDan }}</td>
          <td>
            <template v-if="item.rang === 'OTHER'">
              {{ item.rang | refereeRang }}
            </template>
            <template v-else>
              {{ item.rangOther }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    }
  }
}
</script>
