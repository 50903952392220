<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        :label="$tc('form.name')">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('settings.category', 2)">
        <template slot-scope="scope">
          {{ getCategories(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="$feature.registration_fee"
        :label="$tc('general.registration_fee')"
        width="200px"
        align="center">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.alwaysPay" :content="$tc('settings.discipline_always_chargeable')">
            <i class="el-icon-warning" />
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.noPay" :content="$tc('settings.discipline_not_chargeable')">
            <i class="el-icon-error" />
          </el-tooltip>
          <i v-else class="el-icon-success" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('settings.allowed_sexes')"
        width="200px"
        align="center">
        <template slot-scope="scope">
          {{ !scope.row.forSex ? $tc('general.both') : scope.row.forSex === '1' ? $tc('general.men') : $tc('general.women') }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        align="right"
        width="120px">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="(command) => handleCommand(command, scope.row)">
            <el-button type="primary" icon="el-icon-more" circle />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit"><i class="el-icon-edit" />{{ $tc('general.edit') }}</el-dropdown-item>
              <el-dropdown-item command="archive"><i class="el-icon-takeaway-box" />{{ scope.row.isArchived ? $tc('general.unarchive') : $tc('general.archive', 2) }}</el-dropdown-item>
              <el-dropdown-item command="delete"><i class="el-icon-delete" />{{ $tc('general.delete') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getCategories (item) {
      const categories = item.disciplineCategories?.map(disc => disc.category)
      return categories.map(cat => cat.name).join(', ')
    },

    handleCommand (command, item) {
      if (command === 'edit') return this.$emit('edit', item)
      if (command === 'archive') return this.askArchive(item)
      if (command === 'delete') return this.beforeRemove(item)
    },

    async askArchive (item) {
      try {
        await this.$confirm(this.$tc(item.isArchived ? 'messages.ask_unarchive_discipline' : 'messages.ask_archive_discipline'))

        this.doArchive(item, !item.isArchived)
      } catch {}
    },

    async doArchive (item, isArchived = true) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('disciplines/update', {
          id: item.id,
          payload: { isArchived }
        })
        this.$message.success(this.$tc(isArchived ? 'messages.success_archived_discipline' : 'messages.success_unarchived_discipline'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    async beforeRemove (discipline) {
      try {
        await this.$confirm(`${this.$tc('messages.ask_remove_discipline')}: ${discipline.name}?`)
        this.onDelete(discipline)
      } catch {}
    },

    async onDelete (discipline) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('disciplines/delete', discipline.id)
        this.$message.success(this.$tc('messages.success_remove_discipline'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-icon-error {
    color: $danger;
  }
  .el-icon-success {
    color: $success;
  }
</style>
