import axios from 'axios'
import moment from 'moment'

export default {
  state: {
    page: null,
    settings: null,
    activeTournament: null
  },

  actions: {
    async loadData ({ commit }) {
      try {
        const response = await axios.get('/data')
        commit('setData', response.data)
        return response.data
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },

  mutations: {
    set (state, data) {
      const title = state.settings?.title || process.env.VUE_APP_TITLE
      document.title = data?.title ? `${data.title} - ${title}` : title
      state.page = data
    },

    setData (state, data) {
      state.settings = data?.settings
      const activeTournament = data?.activeTournament
      if (activeTournament) {
        activeTournament.isActive = moment(activeTournament.endDate) > moment()
      }
      state.activeTournament = data?.activeTournament
    },

    setActiveTournament (state, data) {
      state.activeTournament = data
    }
  },

  namespaced: true
}
