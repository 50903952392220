export const registrationOrderBy = (competitors, orderBy) => {
  if (orderBy === 'ALPHA') {
    return [...competitors].sort((a, b) => {
      const aName = a.competitor.firstName + a.competitor.lastName
      const bName = b.competitor.firstName + b.competitor.lastName
      return aName > bName ? 1 : -1
    })
  }
  if (orderBy === 'DESC') return [...competitors].sort((a, b) => a.id < b.id ? 1 : -1)
  if (orderBy === 'ASC') return [...competitors].sort((a, b) => a.id > b.id ? 1 : -1)
  return [...competitors].sort((a, b) => a.club?.id > b.club?.id ? 1 : -1)
}
