<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        :label="$tc('form.image')"
        width="100px">
        <template slot-scope="scope">
          <main-image :src="$file('avatars', scope.row.avatar)" style="width: 75px; height: 75px"/>
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.first_name')"
        width="200px">
        <template slot-scope="scope">
          {{ scope.row.firstName }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.last_name')">
        <template slot-scope="scope">
          {{ scope.row.lastName }}
        </template>
      </el-table-column>

      <template v-if="showClubName">
        <el-table-column
          :label="$tc('club.club_name')">
          <template slot-scope="scope">
            {{ scope.row.club ? scope.row.club.name : '' }}
          </template>
        </el-table-column>
      </template>

      <template v-if="$config.isWff">
        <el-table-column
          :label="$tc('form.no_certificate_wff')">
          <template slot-scope="scope">
            {{ scope.row.noCertificateWff }}
          </template>
        </el-table-column>

        <el-table-column
          :label="$tc('form.no_referee_certificate_wff')">
          <template slot-scope="scope">
            {{ scope.row.noRefereeCertificateWff }}
          </template>
        </el-table-column>

        <el-table-column
          :label="$tc('form.no_fudokan_passport')">
          <template slot-scope="scope">
            {{ scope.row.noFudokanPassport }}
          </template>
        </el-table-column>
      </template>

      <el-table-column
        :label="$tc('form.day')">
        <template slot-scope="scope">
          {{ scope.row.dan | refereeDan }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('club.referee_rang')">
        <template slot-scope="scope">
          <template v-if="scope.row.rang === 'OTHER'">
            {{ scope.row.rangOther }}
          </template>
          <template v-else>
            {{ scope.row.rang | refereeRang }}
          </template>
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        align="right">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" circle @click="$emit('edit', scope.row)" />
          <el-button type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  methods: {
    async beforeRemove (referee) {
      try {
        await this.$confirm(`${this.$tc('messages.ask_remove_referee')}: ${referee.firstName} ${referee.lastName}`)
        if (this.customLogic) return this.$emit('custom-delete', referee)

        this.onDelete(referee)
      } catch {}
    },

    async onDelete (referee) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('referees/delete', referee.id)
        this.$message.success(this.$tc('messages.success_deleted_referee'))
        this.$emit('delete', referee)
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
