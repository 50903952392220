import Vue from 'vue'
import axios from 'axios'

const baseURL = (process.env.NODE_ENV === 'development') ? process.env.VUE_APP_API_LOCAL_URL : process.env.VUE_APP_API_URL
const baseURLAPI = baseURL + '/api'

axios.defaults.baseURL = baseURLAPI
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

const token = localStorage.getItem('token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

const currency = process.env.VUE_APP_CURRENCY

export const formatCurrency = (val) => {
  if (currency === '$') {
    return `$${val}`
  }

  return `${val} ${currency}`
}

export const features = {
  registration_fee: process.env.VUE_APP_FEATURE_FEE === 'true',
  use_country: process.env.VUE_APP_USE_COUNTRY === 'true'
}

const data = {
  app: process.env.VUE_APP_NAME,
  isWff: process.env.VUE_APP_NAME === 'wff',
  title: process.env.VUE_APP_TITLE,
  baseURL: baseURL,
  baseURLAPI: baseURLAPI,
  currency
}

Vue.prototype.$feature = features
Vue.prototype.$formatCurrency = formatCurrency
Vue.prototype.$defaultErrorMessage = 'There was an error. Please try again.'
Vue.prototype.$config = data
Vue.prototype.$file = (path, filename, id = null) => {
  if (!filename) return null
  const subfolder = id ? `/${id}` : ''
  const base = `${baseURL}/files/${path}${subfolder}/${filename}`
  return base
}

export default data
