<template>
  <panel class="navigation">
    <div v-for="(item, index) in data" :key="item.title">
      <el-divider v-if="index !== 0" />

      <router-link :to="item.to">
        <span>{{ item.title }}</span>
        <i v-if="$route.name === item.to.name" class="el-icon-arrow-right" />
      </router-link>

    </div>
  </panel>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>
