<template>
  <panel ref="panel" :title="`${$tc('club.official', 2)} (${officials.length})`" collapsable>
    <template v-slot:actions>
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManageOfficial = true">
        {{ $tc('club.add_official') }}
      </el-button>

      <el-tooltip v-if="officials.length" :content="$tc('general.print')" placement="top">
        <el-button
          icon="el-icon-printer"
          size="medium"
          circle
          @click="openPrint($tc('club.official', 2), $refs.printTable ? $refs.printTable.$el : null)" />
      </el-tooltip>
    </template>

    <officials-table
      :data="officials"
      :can-edit="canEdit"
      :custom-logic="customLogic"
      :show-club-name="showClubName"
      @edit="openEdit($event)"
      @custom-delete="$emit('custom-delete', $event)"
      @delete="$emit('delete', $event)" />

    <print-officials-table ref="printTable" v-show="false" :data="officials" :show-club-name="showClubName" />

    <manage-official
      v-if="openManageOfficial"
      :club="club"
      :official="editing"
      :custom-logic="customLogic"
      @edit="onEdit($event)"
      @custom-add="$emit('custom-add', $event); close()"
      @add="$emit('add', $event); close()"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'

import OfficialsTable from './OfficialsTable'
import ManageOfficial from './ManageOfficial'
import PrintOfficialsTable from './PrintOfficialsTable'

export default {
  components: {
    OfficialsTable,
    ManageOfficial,
    PrintOfficialsTable
  },

  props: {
    club: {
      type: Object,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    officials: {
      type: Array,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      openPrint,

      editing: null,
      openManageOfficial: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    onEdit (official) {
      const club = this.$store.getters['clubs/get'](official.clubId)
      if (club) {
        this.$store.commit('clubs/setSingle', this.getClubUpdateOfficial(club, official))
      }

      if (this.user.club?.id === official.clubId) {
        this.$store.commit('auth/updateUsersClub', this.getClubUpdateOfficial(this.user.club, official))
      }

      this.close()
    },

    getClubUpdateOfficial (club, official) {
      const idx = club.officials?.findIndex(m => m.id === official.id)
      const newData = [...club.officials]
      newData[idx] = official
      club.officials = newData
      return club
    },

    openEdit (official) {
      this.editing = official
      this.openManageOfficial = true
    },

    close () {
      this.editing = null
      this.openManageOfficial = false
    }
  }
}
</script>
