<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>{{ $tc('settings.logo') }}</th>
          <th>{{ $tc('club.club_name') }}</th>
          <th>{{ $tc('club.short_name') }}</th>
          <th>{{ $tc('form.city') }}</th>
          <th>{{ $tc('form.address') }}</th>
          <th>{{ $tc('form.email') }}</th>
          <th>{{ $tc('form.website') }}</th>
          <th>{{ $tc('form.contact_person') }}</th>
          <th>{{ $tc('form.mobile') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td style="width: 80px">
            <div class="image">
              <img :src="$file('avatars', item.logo)" style="width: 75px; height: 75px; object-fit: cover;" class="image" />
            </div>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.shortName }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.website }}</td>
          <td>{{ item.contact }}</td>
          <td>{{ item.mobile }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    }
  }
}
</script>
