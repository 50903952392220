'use strict'

exports.__esModule = true
exports.default = {
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Otkaži'
    },
    datepicker: {
      now: 'Sad',
      today: 'Danas',
      cancel: 'Otkaži',
      clear: 'Očisti',
      confirm: 'OK',
      selectDate: 'Odaberi datum',
      selectTime: 'Odaberi vreme',
      startDate: 'Datum početka',
      startTime: 'Vreme početka',
      endDate: 'Datum završetka',
      endTime: 'Vreme završetka',
      prevYear: 'Prethodna godina',
      nextYear: 'Sledeća godina',
      prevMonth: 'Prethodni mesec',
      nextMonth: 'Sljedeći mesec',
      year: '',
      month1: 'Januar',
      month2: 'Februar',
      month3: 'Mart',
      month4: 'April',
      month5: 'Maj',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Avgust',
      month9: 'Septembar',
      month10: 'Oktobar',
      month11: 'Novembar',
      month12: 'Decembar',
      week: 'neelja',
      weeks: {
        sun: 'Ned',
        mon: 'Pon',
        tue: 'Uto',
        wed: 'Sre',
        thu: 'Čet',
        fri: 'Pet',
        sat: 'Sub'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'Maj',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Avg',
        sep: 'Sep',
        oct: 'Okt',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Učitavanje',
      noMatch: 'Nema pronađenih podataka',
      noData: 'Nema podataka',
      placeholder: 'Izaberi'
    },
    cascader: {
      noMatch: 'Nema pronađenih podataka',
      loading: 'Učitavanje',
      placeholder: 'Izaberi',
      noData: 'Nema podataka'
    },
    pagination: {
      goto: 'Idi na',
      pagesize: '/stranica',
      total: 'Ukupno {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Poruka',
      confirm: 'OK',
      cancel: 'Otkaži',
      error: 'Pogrešan unos'
    },
    upload: {
      deleteTip: 'pritisnite izbriši za brisanje',
      delete: 'Izbriši',
      preview: 'Pregled',
      continue: 'Nastavak'
    },
    table: {
      emptyText: 'Nema podataka',
      confirmFilter: 'Potvrdi',
      resetFilter: 'Resetuj',
      clearFilter: 'Sve',
      sumText: 'Suma'
    },
    tree: {
      emptyText: 'Nema podataka'
    },
    transfer: {
      noMatch: 'Nema pronađenih podataka',
      noData: 'Nema podataka',
      titles: ['Lista 1', 'Lista 2'], // to be translated
      filterPlaceholder: 'Unesite ključnu reč', // to be translated
      noCheckedFormat: '{total} stavki', // to be translated
      hasCheckedFormat: '{checked}/{total} checked' // to be translated
    },
    image: {
      error: 'FAILED' // to be translated
    },
    pageHeader: {
      title: 'Nazad' // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Da', // to be translated
      cancelButtonText: 'Ne' // to be translated
    }
  }
}
