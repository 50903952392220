import Vue from 'vue'

import Panel from '../components/panel/Panel'
import Input from '../components/forms/Input'
import Image from '../components/images/Image'
import Loader from '../components/other/Loader'
import Select from '../components/forms/Select'
import Drawer from '../components/drawer/Drawer'
import ErrorBox from '@/components/other/ErrorBox'
import Checkbox from '../components/forms/Checkbox'
import Textarea from '../components/forms/Textarea'
import DatePicker from '../components/forms/DatePicker'
import DataList from '../components/data-list/DataList'
import SideMenu from '../components/side-menu/SideMenu'
import UploadInput from '../components/forms/UploadInput'

Vue.component('panel', Panel)
Vue.component('loader', Loader)
Vue.component('main-image', Image)
Vue.component('main-input', Input)
Vue.component('side-menu', SideMenu)
Vue.component('main-select', Select)
Vue.component('error-box', ErrorBox)
Vue.component('data-list', DataList)
Vue.component('main-drawer', Drawer)
Vue.component('main-checkbox', Checkbox)
Vue.component('main-textarea', Textarea)
Vue.component('main-date-picker', DatePicker)
Vue.component('main-upload-input', UploadInput)

Vue.component('panel', Panel)
