<template>
  <panel :title="$tc('auth.club_info')" class="page">
    <el-form
      :model="form"
      label-width="0">
      <el-row :gutter="20">
        <el-col :sm="24" :md="15">
          <main-input v-model="form.name" :label="$tc('club.club_name')" :validator="$v.form.name" />
        </el-col>
        <el-col :sm="24" :md="9">
          <main-input v-model="form.shortName" :label="$tc('club.short_name')" :validator="$v.form.shortName" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.city" :label="$tc('form.city')" :validator="$v.form.city" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.address" :label="$tc('form.address')" :validator="$v.form.address" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.email" :label="$tc('form.email')" :validator="$v.form.email" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.website" :label="$tc('form.website')" :validator="$v.form.website" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.contact" :label="$tc('form.contact_person')" :validator="$v.form.contact" />
        </el-col>
        <el-col :sm="24" :md="12">
          <main-input v-model="form.mobile" :label="$tc('form.mobile')" :validator="$v.form.mobile" />
        </el-col>
      </el-row>
    </el-form>

    <template v-slot:footer>
      <div class="flex-between large">
        <el-button class="xxl" icon="el-icon-back" @click="$emit('prev')">{{ $tc('general.prev_step') }}</el-button>
        <el-button class="xxl" type="primary" @click="next()">{{ $tc('general.next') }}</el-button>
      </div>
    </template>
  </panel>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        name: null,
        shortName: null,
        city: null,
        address: null,
        email: null,
        website: null,
        contact: null,
        mobile: null
      }
    }
  },

  methods: {
    async next () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.$emit('next', this.form)
    }
  },

  validations: {
    form: {
      name: { required },
      shortName: { required },
      city: { required },
      address: { required },
      email: { required, email },
      contact: { required },
      mobile: { required }
    }
  }
}
</script>
