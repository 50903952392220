<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      :loading="loading"
      style="width: 100%">
      <el-table-column
        v-if="!hiddenFields.includes('logo')"
        :label="$tc('settings.logo')"
        width="100px">
        <template slot-scope="scope">
          <main-image :src="$file('avatars', scope.row.logo)" style="width: 75px; height: 75px" class="image" />
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('name')"
        :label="$tc('club.club_name')">
        <template slot-scope="scope">
          <router-link :to="link ? link(scope.row) : { name: 'ClubView', params: { id: scope.row.id} }" class="link">
            {{ scope.row.name }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('shortName')"
        :label="$tc('club.short_name')">
        <template slot-scope="scope">
          <router-link :to="link ? link(scope.row) : { name: 'ClubView', params: { id: scope.row.id} }" class="link">
            {{ scope.row.shortName }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('city')"
        :label="$tc('form.city')">
        <template slot-scope="scope">
          {{ scope.row.city }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('address')"
        :label="$tc('form.address')">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('email')"
        :label="$tc('form.email')">
        <template slot-scope="scope">
          {{ scope.row.email }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('website')"
        :label="$tc('form.website')">
        <template slot-scope="scope">
          {{ scope.row.website }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('contact')"
        :label="$tc('form.contact_person')">
        <template slot-scope="scope">
          {{ scope.row.contact }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hiddenFields.includes('mobile')"
        :label="$tc('form.mobile')">
        <template slot-scope="scope">
          {{ scope.row.mobile }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="user.isAdmin"
        :label="$tc('general.actions')"
        align="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.id !== clubId" type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    link: {
      type: Function,
      default: null
    },

    hiddenFields: {
      type: Array,
      default: () => ['name']
    }
  },

  computed: {
    clubId () {
      return this.user?.club?.id
    },

    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    async beforeRemove (club) {
      try {
        await this.$confirm(`${this.$tc('club.ask_delete_club')}: ${club.shortName}`)
        this.onDelete(club)
      } catch {}
    },

    async onDelete (club) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('clubs/delete', club.id)
        this.$message.success(this.$tc('messages.success_deleted_club'))
        this.$emit('delete', club)
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
