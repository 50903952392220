export const getCategories = (competitors) => {
  const categories = competitors.reduce((acc, competitor) => {
    const ids = competitor.categories.filter(category => !acc.includes(category.id))
    acc.push(...ids)
    return acc
  }, []).map(cat => ({
    name: cat.category.name,
    disciplines: this.getDisciplines(cat.categoryId, competitors)
  }))

  return categories
}

export const getDisciplines = (catId, competitors) => {
  return competitors.reduce((acc, competitor) => {
    const categories = competitor.categories.filter(category => category.categoryId === catId)
    const disciplines = categories.reduce((acc, category) => {
      acc.push(...category.disciplines)
      return acc
    }, [])
    acc.push(...disciplines)
    return acc
  }, []).map(disc => ({
    name: disc.discipline.name,
    items: this.getCompetitors(catId, disc.disciplineId, competitors)
  }))
}

export const getCompetitors = (catId, disciplineId, competitors = [], filter) => {
  const c = competitors.reduce((acc, competitor) => {
    const categories = competitor.categories?.filter(category => category.categoryId === catId)
    const disciplines = categories.reduce((acc, category) => {
      acc.push(...category.disciplines)
      return acc
    }, [])
    const disciplinesIds = disciplines.map(disc => disc.disciplineId)
    if (disciplinesIds.includes(disciplineId)) {
      acc.push(competitor)
    }
    return acc
  }, [])
  return filter ? c.filter(d => filter === 'M' ? d.competitor.isMale : !d.competitor.isMale) : c
}

export const getCompetitorName = (competitor, assignedNumber) => {
  const assignedNumberText = assignedNumber ? `#${assignedNumber}. ` : ''
  const fullName = `${competitor.firstName} ${competitor.lastName}`
  return `${assignedNumberText}${fullName} (${competitor.isMale ? 'M' : 'Ž'})`
}

export const getCompetitorNames = (registration, assignedNumber) => {
  const keys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']
  const competitors = keys.map(key => registration[key]).filter(item => !!item).map(competitor => getCompetitorName(competitor, assignedNumber))
  return competitors.join(', ')
}
