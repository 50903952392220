<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      style="width: 100%">
      <el-table-column
        :label="$tc('general.n0')"
        width="50px">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}.
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.image')"
        width="100px">
        <template slot-scope="scope">
          <main-image :src="$file('avatars', scope.row.avatar)" style="width: 75px; height: 75px" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.first_name')"
        width="200px">
        <template slot-scope="scope">
          {{ scope.row.firstName }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.last_name')">
        <template slot-scope="scope">
          {{ scope.row.lastName }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.date_of_birth')">
        <template slot-scope="scope">
          {{ scope.row.born | date }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.gender')">
        <template slot-scope="scope">
          {{ scope.row.isMale | sex }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('form.belt')">
        <template slot-scope="scope">
          {{ scope.row.belt | belt }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        align="right">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" circle @click="$emit('edit', scope.row)" />
          <el-button type="danger" icon="el-icon-delete" circle @click="beforeRemove(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    async beforeRemove (member) {
      try {
        await this.$confirm(`Da li si siguran da želiš da ukloniš sudiju: ${member.firstName} ${member.lastName}`)
        if (this.customLogic) return this.$emit('custom-delete', member)

        this.onDelete(member)
      } catch {}
    },
    async onDelete (member) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('members/delete', member.id)
        this.$message.success('Uspešno ste uklonili sudiju.')
        this.$emit('delete', member)
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
