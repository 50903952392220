<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <div
      v-if="!showAsText"
      class="input-wrapper">
      <el-input
        v-model="val"
        v-bind="options"
        :type="type"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :show-word-limit="!!maxlength"
        :min="min"
        :max="max"
        :disabled="disabled"
        :showPassword="showPassword"
        @blur="$emit('blur')"
        @keyup.native="$emit('keyup', $event)"
        @keyup.enter.native="$emit('enter')"
        @change="$emit('change', $event)">
        <template v-slot:prepend>
          <template v-if="prepend">
            {{ prepend }}
          </template>
        </template>
        <template v-slot:append>
          <template v-if="append">
            {{ append }}
          </template>
        </template>
      </el-input>
      <div
        v-if="$slots.additional"
        class="additional">
        <slot name="additional" />
      </div>
    </div>
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'text'
    },

    showPassword: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: [Number, String],
      default: null
    },

    min: {
      type: [Number, String],
      default: null
    },

    max: {
      type: [Number, String],
      default: null
    },

    prepend: {
      type: String,
      default: null
    },

    append: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
  .input-wrapper {
    display: flex;
    .additional {
      margin-left: 5px;
    }
  }
</style>
