<template>
  <panel v-if="page" class="panel-header">
    <div class="header">
      <div>
        <div class="page-info">
          <!-- <div v-if="page.back" class="back">
            <router-link :to="page.back">
              <i class="el-icon-back" /> Back
            </router-link>

            <el-divider direction="vertical" class="divider" />
          </div> -->
          <h1>{{ page.title }}</h1>
        </div>

        <el-breadcrumb
          v-if="page.breadcrumbs && !!page.breadcrumbs.length"
          separator="/" class="breadcumbs">
          <el-breadcrumb-item
            v-for="(breadcrumb, index) in page.breadcrumbs"
            :key="index"
            :to="breadcrumb.to || null">{{ breadcrumb.text }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="actions">
        <v-teleport-location name="header" class="center" />
      </div>
    </div>
  </panel>
</template>

<script>

export default {
  computed: {
    page () {
      return this.$store.state.app.page
    },

    back () {
      return this.page?.back ? this.goBack : () => {}
    }
  },

  methods: {
    goBack () {
      this.$router.push(this.page?.back)
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-header {
    margin-bottom: 30px;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page-info {
        .back {
          display: inline-block;
          vertical-align: middle;
        }
        h1 {
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          margin: 0;
        }
        .divider {
          margin: 0 133px;;
        }
      }
    }

    .breadcumbs {
      margin-top: 10px;
    }
  }
  @media (max-width: 768px) {
    .panel-header {
      margin-bottom: 20px;
      .header {
        flex-direction: column;
        align-items: flex-start;
      }
      .actions {
        margin-top: 20px;
      }
    }
  }
</style>
