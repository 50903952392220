<template>
  <div class="main-table-wrapper">
    <el-table
      :data="data"
      width="100%">
      <el-table-column
        :label="$tc('tournament.tournament_name')"
        min-width="250px">
        <template slot-scope="scope">
          <router-link :to="{ name: 'TournamentView', params: { id: scope.row.id } }" class="link">
            {{ scope.row.name }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('tournament.registration_start_date')"
        width="200px">
        <template slot-scope="scope">
          {{ scope.row.startDate | date('LLL') }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('tournament.registration_end_date')"
        width="200px">
        <template slot-scope="scope">
          <span v-if="scope.row.endDate">
            {{ scope.row.endDate | date('LLL') }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('tournament.competitors', 2)"
        width="150px">
        <template slot-scope="scope">
          {{ scope.row.competitorsCount || 0 }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$tc('tournament.teams')"
        width="100px">
        <template slot-scope="scope">
          {{ scope.row.clubsCount || 0 }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="canEdit"
        :label="$tc('general.actions')"
        width="160px"
        align="right">
        <template slot-scope="scope">
          <el-button type="info" icon="el-icon-edit" circle @click="openEdit(scope.row)" />
          <el-button :disabled="scope.row.isActive" type="primary" icon="el-icon-takeaway-box" circle @click="askArchive(scope.row)" />
          <el-button type="danger" icon="el-icon-delete" circle @click="beforeDelete(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    async askArchive (tournament) {
      try {
        await this.$confirm(this.$tc(tournament.isArchived ? 'messages.ask_unarchive_tournament' : 'messages.ask_archive_tournament'))

        this.doArchive(tournament, !tournament.isArchived)
      } catch {}
    },

    async beforeDelete (tournament) {
      try {
        await this.$confirm(this.$tc('messages.ask_delete_tournament'))

        this.onDelete(tournament)
      } catch {}
    },

    async doArchive (tournament, isArchived = true) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('tournaments/update', {
          id: tournament.id,
          payload: { isArchived }
        })
        this.$message.success(this.$tc(isArchived ? 'messages.success_archived_tournament' : 'messages.success_unarchived_tournament'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    async onDelete (tournament) {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('tournaments/delete', tournament.id)
        this.$message.success(this.$tc('messages.success_deleted_tournament'))
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        loading.close()
      }
    },

    openEdit (tournament) {
      this.$emit('edit', tournament)
    }
  }
}
</script>
