<template>
  <panel :title="$tc('general.club')">
    <error-box v-if="error" :loading="loading" @reload="load()" />

    <clubs-table v-if="!error" :data="clubs" :loading="loading" />
  </panel>
</template>

<script>
import ClubsTable from '@/components/clubs/ClubsTable'

export default {
  components: {
    ClubsTable
  },

  data () {
    return {
      error: false,
      loading: false
    }
  },

  computed: {
    clubs () {
      return this.$store.getters['clubs/list'] || []
    }
  },

  created () {
    this.load()
  },

  methods: {
    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('clubs/load')
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
