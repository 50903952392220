<template>
  <main-drawer :title="isEdit ? $tc('settings.edit_category') : $tc('settings.create_category')" @close="$emit('close')">
    <main-input v-model="form.name" :label="$tc('form.name')" :validator="$v.form.name" />

    <main-checkbox v-model="form.noPay" :label="$tc('settings.category_not_chargeable')" />

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.create')}}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    category: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        name: null,
        noPay: false
      }
    }
  },

  computed: {
    isEdit () {
      return !!this.category
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        name: this.category.name,
        noPay: this.category.noPay
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        if (this.isEdit) {
          await this.$store.dispatch('categories/update', {
            id: this.category.id,
            payload: this.form
          })
        } else {
          await this.$store.dispatch('categories/create', this.form)
        }
        this.$message.success(this.isEdit ? this.$tc('messages.success_updated_category') : this.$tc('messages.success_created_category'))
        this.$emit(this.isEdit ? 'add' : 'edit')
      } catch (error) {
        console.error(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        name: { required }
      }
    }
  }
}
</script>
