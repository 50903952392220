import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import loggedIn from '../common/loggedIn'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const loggedInUser = await loggedIn()

  if (to.matched.some(record => record.meta.not_logged) && loggedInUser) {
    return next({ name: 'Home' })
  }

  if (to.matched.some(record => record.meta.isAdmin) && !loggedInUser?.isAdmin) {
    return next({ name: 'Home' })
  }

  if (to.matched.some(record => record.meta.auth) && !loggedInUser) {
    return next({ name: 'Login' })
  }

  if (to.name === 'MyClub' && !loggedInUser?.club) {
    return next({ name: 'ClubsMain' })
  }

  next()
})

router.afterEach(() => {
  store.commit('app/set', null)
})

export default router
