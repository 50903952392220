<template>
  <div class="error-box">
    <div>
      <h1>{{ $tc('general.ops') }}</h1>
      <h3>Nešto se dogodilo i nismo uspeli da očitamo podatke.</h3>

      <el-button
        icon="el-icon-refresh-left"
        type="primary"
        :loading="loading"
        round
        @click="$emit('reload')">
        Pokušaj ponovo
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 30px;
    box-sizing: border-box;
    img {
      width: 200px;
      margin-bottom: 30px;
    }
    h1 {
      font-size: 32px;
      font-weight: 900;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }
</style>
