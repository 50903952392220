<template>
  <panel :title="$tc('general.registration_fee')">
    <div class="wrapper">
      <el-table
        :data="data"
        style="width: 100%">
        <el-table-column
          :label="$tc('form.description')">
          <template slot-scope="scope">
            {{ scope.row.desc }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$tc('form.price')"
          width="200px">
          <template slot-scope="scope">
            <main-input v-model="form[scope.row.key]" :validator="$v.form[scope.row.key]" :append="$config.currency" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$tc('form.discount')"
          width="170px"
          align="right">
          <template slot-scope="scope">
            <main-input v-model="form[scope.row.break_kee]" :validator="$v.form[scope.row.break_kee]" :append="$config.currency" />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <template v-slot:footer>
      <div class="flex-end">
        <el-button type="primary" :loading="loading" @click="save()">{{ $tc('general.save') }}</el-button>
      </div>
    </template>
  </panel>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators'
import errorHandler from '@/common/errorHandler.js'

export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },

    members: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      form: {
        single_fee_1: null,
        single_fee_1_break: null,
        single_fee_2: null,
        single_fee_2_break: null,
        single_fee_other: null,
        single_fee_other_break: null,
        team_fee: null,
        team_fee_break: null
      },

      data: [
        {
          desc: this.$tc('settings.one_discipline'),
          key: 'single_fee_1',
          break_kee: 'single_fee_1_break'
        },
        {
          desc: this.$tc('settings.two_disciplines'),
          key: 'single_fee_2',
          break_kee: 'single_fee_2_break'
        },
        {
          desc: this.$tc('settings.three_or_more_disciplines'),
          key: 'single_fee_other',
          break_kee: 'single_fee_other_break'
        },
        {
          desc: this.$tc('settings.team_disciplines'),
          key: 'team_fee',
          break_kee: 'team_fee_break'
        }
      ],

      loading: false
    }
  },

  computed: {
    settings () {
      return this.$store.state.app.settings
    }
  },

  created () {
    Object.keys(this.form).forEach(key => (this.form[key] = this.settings[key]))

    this.$store.commit('app/set', {
      title: this.$tc('general.registration_fee'),
      breadcrumbs: [
        {
          text: this.$tc('settings.settings')
        },
        {
          text: this.$tc('general.registration_fee')
        }
      ]
    })
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('settings/update', {
          id: this.settings.id,
          payload: this.form
        })
        this.$message.success(this.$tc('settings.settings_successfully_updated'))
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        single_fee_1: { required, integer },
        single_fee_1_break: { required, integer },
        single_fee_2: { required, integer },
        single_fee_2_break: { required, integer },
        single_fee_other: { required, integer },
        single_fee_other_break: { required, integer },
        team_fee: { required, integer },
        team_fee_break: { required, integer }
      }
    }
  }
}
</script>
