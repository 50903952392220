import store from '@/store'

export default async () => {
  if (store.state.auth.user) return store.state.auth.user
  const token = localStorage.getItem('token')
  if (token) {
    try {
      await store.dispatch('auth/me', token)
      return store.state.auth.user
    } catch {
      return null
    }
  }
  return false
}
