<template>
  <div class="registration-page">
    <panel class="steps">
      <el-steps :active="step" align-center finish-status="success">
        <el-step :title="$tc('auth.profile_information')" />
        <el-step :title="$tc('auth.club_info')" />
        <el-step :title="$tc('general.other')" />
      </el-steps>
    </panel>

    <register-user-info v-show="step === 0" @next="openClubInfo($event)" />
    <register-club-info v-show="step === 1" @prev="step = 0" @next="openOtherInfo($event)" />
    <register-other-info v-show="step === 2" @prev="step = 1" :loading="loading" @register="register($event)" />
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required, minLength, email } from 'vuelidate/lib/validators'

import RegisterUserInfo from '@/components/auth/RegisterUserInfo'
import RegisterClubInfo from '@/components/auth/RegisterClubInfo'
import RegisterOtherInfo from '@/components/auth/RegisterOtherInfo'

export default {
  components: {
    RegisterUserInfo,
    RegisterClubInfo,
    RegisterOtherInfo
  },

  data () {
    return {
      userData: null,
      clubData: null,
      loading: false,
      step: 0
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('auth.register')
    })
  },

  methods: {
    openClubInfo (form) {
      this.userData = form
      this.step++
    },

    openOtherInfo (form) {
      this.clubData = form
      this.step++
    },

    async register (coachesRefereesOfficials) {
      this.loading = true
      try {
        const payload = {
          ...this.userData,
          club: this.clubData,
          ...coachesRefereesOfficials
        }

        await this.$store.dispatch('auth/register', payload)
        this.$message.success(this.$tc('messages.success_register'))
        this.$router.push({ name: 'Login' })
      } catch (error) {
        errorHandler(error)
      } finally {
        this.loading = false
      }
    },

    clearReg () {
      this.form.name = null
      this.form.email = null
      this.form.password = null
    }
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  }
}
</script>

<style lang="scss" scoped>
  .registration-page {
    width: 100%;
    max-width: 1152px;
    padding: 100px 0;
    margin: 0 auto;
    .steps {
      margin-bottom: 100px;
      width: 100%;
    }
  }
    @media (max-width: 1020px) {
       .register-user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
       }
       .page .image {
        margin: 0;
        width: 100%;
       }

       .steps {
        width: 100%;
       }

    }
</style>
