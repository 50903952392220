<template>
  <el-image :src="src" :rounded="rounded" fit="cover">
    <div slot="error" class="image-placeholder">
      <img src="@/assets/images/avatar.png" />
    </div>
  </el-image>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: null
    },

    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .image-placeholder {
    display: block;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
