<template>
  <div class="file" :class="{ clickable: url, small }" :style="{ width }" @click="open()">

    <div class="icon">
      <i class="el-icon-document" />
    </div>
    <div class="name">{{ file.name }}</div>

    <div class="actions">
      <div v-if="url" class="icon-button" @click="open()">
        <i class="el-icon-download" />
      </div>
      <div v-if="deletable" class="icon-button" @click.stop="beforeDelete()">
        <i class="el-icon-delete" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: [File, Object],
      required: true
    },

    url: {
      type: String,
      default: null
    },

    width: {
      type: String,
      default: '350px'
    },

    deletable: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async beforeDelete () {
      try {
        await this.$confirm(`Da li si siguran da želiš da obrišeš ovaj dokument: ${this.file.name} ?`)

        this.$emit('delete')
      } catch {}
    },

    open () {
      if (this.url) {
        window.open(this.url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .file {
    max-width: 100%;
    overflow: hidden;
    height: 50px;
    border: 1px solid $border;
    border-radius: 4px;
    display: flex;
    align-items: center;
    &.clickable .name:hover {
      cursor: pointer;
      color: $primary;
    }

    .name {
      overflow: hidden;
      flex-shrink: 2;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
    }

    .actions {
      flex: 1;
      white-space: nowrap;
      justify-self: flex-end;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-button {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid $border;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
    .icon, .icon-button {
      width: 50px;
    }
    &.small {
      height: 35px;
      .icon, .icon-button {
        width: 40px;
      }
    }
  }
</style>
