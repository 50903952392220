<template>
  <div>
    <CoachesPanel :coaches="form.coaches" @custom-add="addCoach($event)" @custom-delete="removeCoach($event)" custom-logic can-edit />

    <RefereePanel :referees="form.referees" @custom-add="addReferee($event)" @custom-delete="removeReferee($event)" custom-logic can-edit/>

    <OfficialPanel :officials="form.officials" @custom-add="addOfficial($event)" @custom-delete="removeOfficial($event)" custom-logic can-edit />

    <panel>
      <div class="flex-between">
        <el-button class="xxl" icon="el-icon-back" @click="$emit('prev')">{{ $tc('general.prev_step') }}</el-button>
        <el-button :loading="loading" class="xxl" type="success" @click="register()">{{ $tc('auth.register') }}</el-button>
      </div>
    </panel>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import CoachesPanel from '@/components/coaches/CoachesPanel'
import RefereePanel from '@/components/referee/RefereePanel'
import OfficialPanel from '@/components/official/OfficialPanel'

export default {
  components: {
    CoachesPanel,
    RefereePanel,
    OfficialPanel
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: {
        coaches: [],
        referees: [],
        officials: []
      },
      saving: false
    }
  },

  methods: {
    addCoach (coach) {
      this.form.coaches.push({
        ...coach,
        id: uuid()
      })
    },

    removeCoach (coach) {
      const index = this.form.coaches.findIndex(c => c.id === coach.id)
      this.form.coaches.splice(index, 1)
    },

    addReferee (referee) {
      this.form.referees.push({
        ...referee,
        id: uuid()
      })
    },
    removeReferee (coach) {
      const index = this.form.coaches.findIndex(c => c.id === coach.id)
      this.form.coaches.splice(index, 1)
    },

    addOfficial (official) {
      this.form.officials.push({
        ...official,
        id: uuid()
      })
    },

    removeOfficial (coach) {
      const index = this.form.coaches.findIndex(c => c.id === coach.id)
      this.form.coaches.splice(index, 1)
    },

    async register () {
      this.$emit('register', this.form)
    }
  }
}
</script>
