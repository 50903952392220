<template>
  <div class="page">
    <img
      src="@/assets/images/login.svg"
      class="image">

    <panel class="panel">
      <h1>{{ $tc('auth.reset_password') }}</h1>
      <el-form
        :model="form"
        label-width="0">
        <main-input v-model="form.email" :label="$tc('form.email')" :validator="$v.form.email" />
      </el-form>

      <el-button
        class="button"
        type="primary"
        :loading="loading"
        round
        @click="submit()">
        {{ $tc('auth.reset_password') }}
      </el-button>

      <router-link
        :to="{ name: 'Register' }"
        class="link">
        {{ $tc('auth.no_account_register') }}
      </router-link>
    </panel>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        email: null
      },
      loading: false
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('auth.reset_password')
    })
  },

  methods: {
    async submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('auth/forgotPassword', this.form)
        this.$router.push({ name: 'Home' })
        this.$message.success(this.$tc('messages.success_forgot_password_request'))
      } catch (error) {
        console.error(error)
        if (error?.response?.status === 404) {
          this.$message.error(this.$tc('messages.error_unknown_account_email'))
        } else {
          this.$message.error(this.$tc('messages.error_unknown'))
        }
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      email: { email, required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .image {
      width: 500px;
      margin-right: 150px;
    }
    .panel {
      width: 500px;
      padding: 30px 50px;

      .button {
        margin-top: 30px;
        width: 100%;
        height: 50px;
        border-radius: 9999px;
      }
      .link {
        display: block;
        text-align: center;
        margin-top: 20px;
        font-size: 17px;
        color: $dark;
      }
    }
  }

   @media (max-width: 600px) {
      .page .image {
        margin: 0;
        width: 100%;
      }
    }
</style>
