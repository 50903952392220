<template>
  <div>
    <error-box v-if="error" @reload="load()" />

    <loader v-else-if="loading" />

    <template v-if="!error && !loading && club">
      <ClubPanel :club="club" :can-edit="canEdit" @edit="onClubEdit($event)" />

      <MembersPanel :key="membersRenderKey" :club="club" :members="club.members" :can-edit="canEdit" @add="updateClubAdd('members', $event)" @delete="updateClubDelete('members', $event)" />

      <CoachesPanel :key="coachesRenderKey" :club="club" :coaches="club.coaches" :can-edit="canEdit" @add="updateClubAdd('coaches', $event)" @delete="updateClubDelete('coaches', $event)" />

      <RefereePanel :key="refereesRenderKey" :club="club" :referees="club.referees" :can-edit="canEdit" @add="updateClubAdd('referees', $event)" @delete="updateClubDelete('referees', $event)" />

      <OfficialPanel :key="officialsRenderKey" :club="club" :officials="club.officials" :can-edit="canEdit" @add="updateClubAdd('officials', $event)" @delete="updateClubDelete('officials', $event)" />
    </template>
  </div>
</template>

<script>

import ClubPanel from '@/components/clubs/ClubPanel'
import MembersPanel from '@/components/members/MembersPanel'
import CoachesPanel from '@/components/coaches/CoachesPanel'
import RefereePanel from '@/components/referee/RefereePanel'
import OfficialPanel from '@/components/official/OfficialPanel'

export default {
  components: {
    ClubPanel,
    MembersPanel,
    CoachesPanel,
    RefereePanel,
    OfficialPanel
  },

  data () {
    return {
      openManageCoach: false,
      openManageReferee: false,
      openManageOfficial: false,

      error: false,
      loading: false,

      membersRenderKey: 1,
      coachesRenderKey: 100,
      refereesRenderKey: 1000,
      officialsRenderKey: 10000
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    myOwnClub () {
      return this.$route.name !== 'ClubView'
    },

    clubId () {
      return this.$route.params.id ? parseInt(this.$route.params?.id) : this.user.club?.id
    },

    club () {
      if (this.myOwnClub) return this.user.club
      return this.$store.getters['clubs/get'](this.clubId)
    },

    canEdit () {
      return this.user.isAdmin || this.user.club?.id === this.clubId
    }
  },

  watch: {
    'club.members' () {
      this.membersRenderKey++
    },

    'club.coaches' () {
      this.coachesRenderKey++
    },

    'club.referees' () {
      this.refereesRenderKey++
    },

    'club.officials' () {
      this.officialsRenderKey++
    },

    '$route.params.id' () {
      this.load()
    }
  },

  created () {
    if (!this.myOwnClub) {
      this.load()
    } else {
      this.setPageData()
    }
  },

  methods: {
    setPageData () {
      this.$store.commit('app/set', {
        title: this.club.shortName
      })
    },

    addCoach (item) {
      this.updateClub('coaches', item)
    },

    addReferee (item) {
      this.updateClub('referees', item)
    },

    addOfficial (item) {
      this.updateClub('officials', item)
    },

    addMember (item) {
      this.updateClub('members', item)
    },

    updateClubDelete (property, item) {
      const newClub = {
        ...this.club,
        [property]: this.club[property].filter(i => i.id !== item.id)
      }

      if (this.myOwnClub) this.updateCurrentUser({ ...this.user, club: newClub })
      else this.$store.commit('clubs/setSingle', newClub)
    },

    updateClubAdd (property, item) {
      const newClub = {
        ...this.club,
        [property]: [...this.club[property], item]
      }

      if (this.myOwnClub) this.updateCurrentUser({ ...this.user, club: newClub })
      else this.$store.commit('clubs/setSingle', newClub)
    },

    onClubEdit (club) {
      if (this.myOwnClub) {
        this.updateCurrentUser({
          ...this.user,
          club
        })
      }
    },

    updateCurrentUser (user) {
      this.$store.commit('auth/setUser', user)
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('clubs/get', this.clubId)
        this.setPageData()
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
