<template>
  <panel :title="club.name">
    <template v-slot:actions>
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-edit"
        size="medium"
        round
        @click="openManageClub = true">
        {{ $tc('general.edit') }}
      </el-button>
    </template>

    <div class="club-details">
      <main-image :src="$file('avatars', club.logo)" style="width: 150px; height: 150px" class="image" />

      <data-list :list="dataList" />
    </div>

    <manage-club
      v-if="openManageClub"
      :club="club" @edit="$emit('edit', $event); openManageClub = false"
      @close="openManageClub = false" />
  </panel>
</template>

<script>
import ManageClub from './ManageClub.vue'

export default {
  components: {
    ManageClub
  },

  props: {
    club: {
      type: Object,
      required: true
    },

    canEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      openManageClub: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    dataList () {
      if (!this.club) return []

      const data = [
        {
          label: this.$tc('club.short_name'),
          text: this.club.shortName
        },
        {
          label: this.$tc('form.city'),
          text: this.club.city
        },
        {
          label: this.$tc('form.address'),
          text: this.club.address
        },
        {
          label: this.$tc('form.email'),
          text: this.club.email
        },
        {
          label: this.$tc('form.website'),
          text: this.club.website
        },
        {
          label: this.$tc('form.contact_person'),
          text: this.club.contact
        },
        {
          label: this.$tc('form.mobile'),
          text: this.club.mobile
        }
      ]

      if (this.user.isAdmin) {
        data.push({
          label: this.$tc('general.registration_fee_discount'),
          text: this.club.costReduction ? this.club.costReduction + '%' : '/'
        })
      }

      return data
    }
  }
}
</script>

<style lang="scss" scoped>
  .club-details {
    display: flex;
    .image {
      margin-right: 20px;
    }
  }
</style>
