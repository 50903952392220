<template>
  <div class="flex-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :width="`${size}px`"
      :height="`${size}px`"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="spinner">
      <circle cx="50" cy="50" fill="none" :stroke="color" stroke-width="8" r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138" transform="matrix(1,0,0,1,0,0)"
        style="transform:matrix(1, 0, 0, 1, 0, 0);animation-play-state:paused"></circle>
    </svg>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: 50
    },

    color: {
      type: String,
      default: '#272847'
    }
  }
}
</script>
