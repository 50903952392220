<template>
  <div>
    <error-box v-if="error" @reload="load()" />

    <loader v-else-if="loading" />

    <template v-if="!error && !loading && tournament">
      <el-row :gutter="20" style="margin-bottom: 30px;">
        <el-col :sm="24" :md="16">
          <TournamentPanel :tournament="tournament" />

          <template v-if="user.club">
            <CompetitorsPanel
              :club="user.club"
              :tournament="tournament"
              :competitors="myTournamentCompetitors"
              :loader="competitorsLoader"
              :title="$tc('tournament.my_registered_competitors')"
              can-edit
              @registration-fee="openRegistrationFee(myTournamentCompetitors, myTournamentTeams, user.club.shortName)" />

            <TeamsPanel
              :club="user.club"
              :tournament="tournament"
              :teams="myTournamentTeams"
              :loader="teamsLoader"
              :title="$tc('tournament.my_registered_teams')"
              can-edit
              @registration-fee="openRegistrationFee(myTournamentCompetitors, myTournamentTeams, user.club.shortName)" />
          </template>

          <CompetitorsPanel
            :tournament="tournament"
            :club="null"
            :competitors="tournamentCompetitors"
            :loader="competitorsLoader"
            :title="$tc('tournament.all_registered_competitors')"
            show-club-name
            hide-add
            @registration-fee="openRegistrationFee(tournamentCompetitors, tournamentTeams)" />

          <TeamsPanel
            :tournament="tournament"
            :club="null"
            :teams="tournamentTeams"
            :loader="teamsLoader"
            :title="$tc('tournament.all_registered_teams')"
            show-club-name
            hide-add
            @registration-fee="openRegistrationFee(tournamentCompetitors, tournamentTeams)" />

          <template v-if="user.isAdmin">
            <CoachesPanel :coaches="coaches" show-club-name @delete="updateClubDelete('coaches', $event)" />

            <RefereePanel :referees="referees" show-club-name @delete="updateClubDelete('referees', $event)" />

            <OfficialPanel :officials="officials" show-club-name @delete="updateClubDelete('officials', $event)" />
          </template>

          <panel :title="`${$tc('general.club', 2)} (${clubs.length})`" collapsable>
            <template v-slot:actions>
              <el-tooltip v-if="user.isAdmin" :content="$tc('general.print')" placement="top">
                <el-button
                  icon="el-icon-printer"
                  size="medium"
                  circle
                  @click="openPrint($tc('general.club'), $refs.printTable ? $refs.printTable.$el : null)" />
              </el-tooltip>
            </template>

            <ClubsTable :data="clubs" :link="clubPreviewLink" :hidden-fields="['name', 'address', 'email', 'website', 'contact', 'mobile']" />

            <PrintClubsTable ref="printTable" v-show="false" :data="clubs" />
          </panel>

          <TournamentHistory v-if="user.isAdmin" :data="history || []" />
        </el-col>
        <el-col :sm="24" :md="8" class="right-side">
          <panel v-if="!$config.isWff || user.isAdmin" :title="$tc('general.informations')" :loader="competitorsLoader || teamsLoader">
            <data-list :list="infoData" />
          </panel>

          <panel v-if="user.club" :title="$tc('club.my_federation_information')" :loader="competitorsLoader || teamsLoader">
            <data-list :list="myInfoData" />
          </panel>

          <TournamentDisciplinesPanels v-if="!isWff" :tournament="tournament" :teams="tournamentTeams" :competitors="tournamentCompetitors" class="mt-20" />
        </el-col>
      </el-row>
    </template>

    <registration-fee
      v-if="registrationFeePreview"
      :title="registrationFeeTitle"
      :data="registrationFeeCompetitors"
      :team-data="registrationFeeTeams"
      @close="onRegistrationFeeClose" />
  </div>
</template>

<script>
import moment from 'moment'
import openPrint from '@/common/print'
import registrationFeeMixin from '@/components/tournaments/registration-fee'

import ClubsTable from '@/components/clubs/ClubsTable'
import CoachesPanel from '@/components/coaches/CoachesPanel'
import RefereePanel from '@/components/referee/RefereePanel'
import TeamsPanel from '@/components/tournaments//TeamsPanel'
import OfficialPanel from '@/components/official/OfficialPanel'
import PrintClubsTable from '@/components/clubs/PrintClubsTable'
import RegistrationFee from '@/components/tournaments/RegistrationFee'
import TournamentPanel from '@/components/tournaments/TournamentPanel'
import CompetitorsPanel from '@/components/tournaments//CompetitorsPanel'
import TournamentHistory from '@/components/tournaments/TournamentHistory'
import TournamentDisciplinesPanels from '@/components/tournaments/TournamentDisciplinesPanels'

export default {
  mixins: [registrationFeeMixin],

  components: {
    ClubsTable,
    TeamsPanel,
    CoachesPanel,
    RefereePanel,
    OfficialPanel,
    RegistrationFee,
    PrintClubsTable,
    TournamentPanel,
    CompetitorsPanel,
    TournamentHistory,
    TournamentDisciplinesPanels
  },

  data () {
    return {
      error: false,
      loading: false,

      competitorsLoader: false,
      teamsLoader: false,
      historyLoader: false,

      tournamentCompetitors: [],
      tournamentTeams: [],
      history: [],

      openPrint,

      registrationFeePreview: null,
      registrationFeeTitle: null,
      registrationFeeCompetitors: null,
      registrationFeeTeams: null
    }
  },

  computed: {
    isWff () {
      return this.$config.isWff
    },

    allData () {
      return [
        ...this.tournamentCompetitors,
        ...this.tournamentTeams.map(item => ({
          ...item,
          isTeam: true
        }))
      ]
    },

    myAllData () {
      return [
        ...this.myTournamentCompetitors,
        ...this.myTournamentTeams.map(item => ({
          ...item,
          isTeam: true
        }))
      ]
    },

    registrationFee () {
      return this.calculateFee(this.allData)
    },

    myRegistrationFee () {
      return this.calculateFee(this.myAllData)
    },

    infoData () {
      const data = [
        {
          label: this.$tc('tournament.registration_end_date'),
          text: this.tournament.endDate ? moment(this.tournament.endDate).format('DD.MM.YYYY HH:mm') : '/'
        },
        {
          label: this.$tc('tournament.number_of_registered_competitors'),
          text: this.tournamentCompetitors?.length || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.tournamentCompetitors) || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_team_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.tournamentTeams) || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_clubs'),
          text: this.tournament.clubs?.length || '0'
        }
      ]

      if (this.user.isAdmin && this.$feature.registration_fee) {
        data.push(
          {
            label: this.$tc('general.registration_fee'),
            text: this.$formatCurrency(this.registrationFee)
          }
        )
      }

      return data
    },

    myInfoData () {
      const data = [
        {
          label: this.$tc('tournament.number_of_registered_competitors'),
          text: this.myTournamentCompetitors?.length || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.myTournamentCompetitors) || '0'
        },
        {
          label: this.$tc('tournament.number_of_registered_team_disciplines'),
          text: this.countDisciplinesFromRegistrations(this.myTournamentTeams) || '0'
        }
      ]

      if (this.user.isAdmin && this.$feature.registration_fee) {
        data.push({
          label: this.$tc('general.registration_fee'),
          text: this.$formatCurrency(this.myRegistrationFee)
        })
      }

      return data
    },

    user () {
      return this.$store.state.auth.user
    },

    viewingActiveTournament () {
      return this.$route.name === 'ActiveTournament'
    },

    activeTournament () {
      return this.$store.state.app.activeTournament
    },

    tournamentId () {
      return this.viewingActiveTournament ? this.activeTournament?.id : this.$route.params.id
    },

    tournament () {
      if (this.viewingActiveTournament) return this.activeTournament

      return this.$store.getters['tournaments/get'](this.tournamentId)
    },

    myTournamentCompetitors () {
      return this.tournamentCompetitors.filter(team => team.club?.id === this.user?.club?.id) || []
    },

    myTournamentTeams () {
      return this.tournamentTeams.filter(team => team.club?.id === this.user.club?.id) || []
    },

    coaches () {
      return this.tournament?.coaches || []
    },

    referees () {
      return this.tournament?.referees || []
    },

    officials () {
      return this.tournament?.officials || []
    },

    clubs () {
      return this.tournament.clubs || []
    },

    allCategories () {
      return this.$store.state.categories.data
    }
  },

  watch: {
    '$route.params.id' () {
      if (!this.viewingActiveTournament) {
        this.load()
        this.setPageData()
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    openRegistrationFee (comps, teams, title) {
      this.registrationFeeTitle = title ? ` - ${title}` : ''
      this.registrationFeeCompetitors = comps
      this.registrationFeeTeams = teams
      this.registrationFeePreview = true
    },

    onRegistrationFeeClose () {
      this.registrationFeeTitle = ''
      this.registrationFeePreview = false
      this.registrationFeeCompetitors = null
      this.registrationFeeTeams = null
    },

    clubPreviewLink (club) {
      if (!this.user.isAdmin) return { name: 'ClubView', params: { id: club.id } }

      if (this.viewingActiveTournament) {
        return { name: 'ActiveTournamentClubView', params: { clubId: club.id } }
      }
      return { name: 'TournamentClubView', params: { id: this.tournament.id, clubId: club.id } }
    },

    calculateFee (registrations) {
      const club = registrations[0]?.club
      const price = registrations.reduce((acc, item) => (item.isTeam ? this.getTeamPrice(item, true) : this.getPrice(item, true)) + acc, 0)
      const costReduction = club?.costReduction || 0
      return ((100 - costReduction) / 100) * price
    },

    countDisciplinesFromRegistrations (registration) {
      return registration?.reduce((acc, item) => {
        const disc = item.categories.reduce((a, c) => a + c.disciplines.length, 0)
        return acc + disc
      }, 0)
    },

    updateTournament (tournament) {
      if (tournament.isActive && this.viewingActiveTournament) {
        this.$store.commit('app/setActiveTournament', tournament)
      }
      this.close()
    },

    setPageData () {
      let breadcrumbs = null

      if (!this.viewingActiveTournament) {
        breadcrumbs = [
          {
            text: 'Turniri',
            to: { name: 'Tournaments' }
          },
          {
            text: this.tournament?.name
          }
        ]
      }

      this.$store.commit('app/set', {
        title: this.tournament?.name,
        breadcrumbs
      })
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('tournaments/get', this.tournamentId)

        // Load other tournament stuff
        this.loadCompetitors()
        this.loadTeams()
        this.loadHistory()

        this.setPageData()
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async loadCompetitors () {
      this.competitorsLoader = true
      try {
        const response = await this.$store.dispatch('tournaments/getCompetitors', this.tournamentId)
        this.tournamentCompetitors = response || []
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.competitorsLoader = false
      }
    },
    async loadTeams () {
      this.teamsLoader = true
      try {
        const response = await this.$store.dispatch('tournaments/getTeams', this.tournamentId)
        this.tournamentTeams = response || []
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.teamsLoader = false
      }
    },

    async loadHistory () {
      this.historyLoader = true
      try {
        const response = await this.$store.dispatch('tournaments/getHistory', this.tournamentId)
        this.history = response || []
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.historyLoader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .grouped-panel {
    margin: 20px 0;
  }

  @media (min-width: 1050px) {
    .panel {
      width: 100% !important;
    }
  }

  @media (max-width: 991px) {
    .right-side {
      margin-top: 20px;
    }
  }
</style>
