<template>
  <panel :title="`${$tc('club.member', 2)} (${members.length || 0})`">
    <template v-slot:actions>
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="openManageMember = true">
        {{ $tc('club.add_member') }}
      </el-button>

      <el-tooltip v-if="members.length" :content="$tc('general.print')" placement="top">
        <el-button
          icon="el-icon-printer"
          size="medium"
          circle
          @click="openPrint($tc('club.member', 2), $refs.printTable ? $refs.printTable.$el : null)" />
      </el-tooltip>
    </template>

    <members-table
      :data="members"
      :can-edit="canEdit"
      @edit="openEdit($event)"
      @delete="$emit('delete', $event)" />

    <print-members-table ref="printTable" v-show="false" :data="members" />

    <manage-member
      v-if="openManageMember"
      :club="club"
      :member="editing"
      @edit="onEdit($event)"
      @add="$emit('add', $event); close()"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'

import MembersTable from './MembersTable'
import ManageMember from './ManageMember'
import PrintMembersTable from './PrintMembersTable'

export default {
  components: {
    MembersTable,
    ManageMember,
    PrintMembersTable
  },

  props: {
    club: {
      type: Object,
      default: null
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    members: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      openPrint,
      editing: null,
      openManageMember: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    onEdit (member) {
      const club = this.$store.getters['clubs/get'](member.clubId)
      if (club) {
        this.$store.commit('clubs/setSingle', this.getClubUpdateMember(club, member))
      }

      if (this.user.club?.id === member.clubId) {
        this.$store.commit('auth/updateUsersClub', this.getClubUpdateMember(this.user.club, member))
      }

      this.close()
    },

    getClubUpdateMember (club, member) {
      const idx = club.members?.findIndex(m => m.id === member.id)
      const newData = [...club.members]
      newData[idx] = member
      club.members = newData
      return club
    },

    openEdit (member) {
      this.editing = member
      this.openManageMember = true
    },

    close () {
      this.editing = null
      this.openManageMember = false
    }
  }
}
</script>
