<template>
  <panel :title="$tc('settings.discipline', 2)">
    <v-teleport to="header">
      <div class="flex mb-20">
        <el-button-group>
          <el-button
            :type="onlyArchived ? 'default' : 'primary'"
            size="medium"
            round
            @click="onlyArchived = false">
            {{ $tc('general.active') }}
          </el-button>
          <el-button
            :type="onlyArchived ? 'primary' : 'default'"
            icon="el-icon-takeaway-box"
            size="medium"
            round
            @click="onlyArchived = true">
            {{ $tc('general.archive') }}
          </el-button>
        </el-button-group>
      </div>
    </v-teleport>

    <template v-slot:actions>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="openManageDiscipline = true">
        {{ $tc('settings.create_discipline') }}
      </el-button>
    </template>

    <error-box v-if="error" :loading="loading" @reload="load()" />

    <discipline-table
      v-if="!error"
      :data="filteredDisciplines"
      @edit="openEdit($event)"
      can-edit
    />

    <discipline-manage
      v-if="openManageDiscipline"
      :discipline="editing"
      @add="close(); load()"
      @edit="close(); load()"
      @close="close()"
    />

  </panel>
</template>

<script>
import DisciplineTable from './DisciplineTable.vue'
import DisciplineManage from './DisciplineManage.vue'

export default {
  components: {
    DisciplineTable,
    DisciplineManage
  },

  data () {
    return {
      onlyArchived: false,
      editing: null,
      openManageDiscipline: false,

      error: false,
      loading: false
    }
  },

  computed: {
    disciplines () {
      return this.$store.state.disciplines.data
    },

    filteredDisciplines () {
      return this.disciplines.filter(discipline => {
        return this.onlyArchived ? !!discipline.isArchived : !discipline.isArchived
      })
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('settings.discipline', 2),
      breadcrumbs: [
        {
          text: this.$tc('settings.settings'),
          to: { name: 'Settings' }
        },
        {
          text: this.$tc('settings.discipline', 2)
        }
      ]
    })
  },

  methods: {
    openEdit (category) {
      this.editing = category
      this.openManageDiscipline = true
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('disciplines/load')
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    close () {
      this.editing = null
      this.openManageDiscipline = false
    }
  }
}
</script>
