<template>
  <panel ref="panel" :title="`${title} (${competitors.length})`" :loader="loader" collapsable>
    <template v-slot:actions>
      <el-button
        v-if="!hideAdd && (user.isAdmin || (tournament.isActive && canEdit))"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManage = true">
        {{ $tc('tournament.register_competitor')}}
      </el-button>

      <template v-if="competitors.length">
        <el-tooltip :content="$tc('general.registration_fee')" placement="top">
          <el-button
            icon="el-icon-tickets"
            size="medium"
            circle
            @click="$emit('registration-fee')" />
        </el-tooltip>

        <el-tooltip :content="$tc('general.print')" placement="top">
          <el-button
            icon="el-icon-printer"
            size="medium"
            circle
            @click="openPrint(club ? club.name : title, $refs.printTable ? $refs.printTable.$el : null)" />
        </el-tooltip>
        <el-tooltip v-if="user.isAdmin" :content="$tc('tournament.assign_numbers')" placement="top">
          <el-button
            icon="el-icon-edit-outline"
            size="medium"
            circle
            @click="askAssignNumbers()" />
        </el-tooltip>

        <el-dropdown v-if="user.isAdmin" trigger="click" @command="setSortBy">
          <el-tooltip :content="$tc('general.order_by')" placement="top">
            <el-button
              icon="el-icon-sort"
              size="medium"
              class="ml-10"
              circle />
          </el-tooltip>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="sortBy === 'ALPHA'" command="ALPHA">{{ $tc('general.alphabetically') }}</el-dropdown-item>
            <el-dropdown-item :disabled="sortBy === 'DESC'" command="DESC">{{ $tc('general.latest_first') }}</el-dropdown-item>
            <el-dropdown-item :disabled="sortBy === 'ASC'" command="ASC">{{ $tc('general.oldest_first') }}</el-dropdown-item>
            <el-dropdown-item :disabled="sortBy === 'CLUB'" command="CLUB">{{ $tc('general.by_club') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </template>

    <competitors-table :data="sortedCompetitors" @remove="updateTournament($event)" @edit="openEdit($event)" :can-edit="canEdit" :show-club-name="showClubName" />

    <print-competitors-table ref="printTable" v-show="false" :data="sortedCompetitors" :show-club-name="showClubName" />

    <manage-competitor
      v-if="openManage"
      :club="club"
      :competitor="editing"
      :tournament="tournament"
      @edit="updateTournament($event)"
      @add="updateTournament($event)"
      @close="close()" />
  </panel>
</template>

<script>
import i18n from '@/i18n'
import openPrint from '@/common/print'
import { registrationOrderBy } from '@/common/util'

import CompetitorsTable from './CompetitorsTable'
import PrintCompetitorsTable from './PrintCompetitorsTable'
import ManageCompetitor from '@/components/tournaments/ManageCompetitor'

export default {
  components: {
    CompetitorsTable,
    ManageCompetitor,
    PrintCompetitorsTable
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    club: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      default: i18n.tc('tournament.registered_competitors')
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    competitors: {
      type: Array,
      required: true
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    loader: {
      type: Boolean,
      default: false
    },

    hideAdd: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      editing: null,
      openManage: false,
      openPrint,
      sortBy: 'DESC'
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    sortedCompetitors () {
      return registrationOrderBy(this.competitors, this.sortBy)
    }
  },

  created () {
    this.sortBy = localStorage.getItem('competitorsSortBy') || 'DESC'
  },

  methods: {
    setSortBy (sortBy) {
      localStorage.setItem('competitorsSortBy', sortBy)
      this.sortBy = sortBy
    },

    askAssignNumbers () {
      this.$confirm(this.$tc('messages.ask_assign_numbers'), undefined, {
        confirmButtonText: this.$tc('general.yes'),
        cancelButtonText: this.$tc('general.no'),
        type: 'warning'
      }).then(this.assignNumbers)
    },

    async assignNumbers () {
      await this.$store.dispatch('tournaments/action', {
        id: this.tournament.id,
        action: 'AssignNumbers'
      })
      await this.$store.dispatch('tournaments/get', this.tournament.id)
      this.$message.success(this.$tc('messages.success_assign_numbers'))
    },

    updateTournament (tournament) {
      this.$store.commit('tournaments/setSingle', tournament)
      if (tournament.isActive) {
        this.$store.commit('app/setActiveTournament', tournament)
      }
      this.close()
    },

    openEdit (competitor) {
      this.editing = competitor
      this.openManage = true
    },

    close () {
      this.editing = null
      this.openManage = false
    }
  }
}
</script>
