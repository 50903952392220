<template>
  <main-drawer title="Sportsko udruženje na turniru" :size="500" @close="$emit('close')">
    <label>Izaberi sportsko udruženje na turniru</label>

    <el-select v-model="form.selectedClub"  @change="onClubChange">
      <el-option
        v-for="club in form.clubOptions"
        :key="club.value.id"
        :label="club.value.name"
        :value="club.value.id">
      </el-option>
    </el-select>
  </main-drawer>
</template>

<script>
export default {
  props: {
    club: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: null,
        loading: false,
        selectedClub: null,
        error: false,
        clubOptions: []
      }
    }
  },
  computed: {
    clubs () {
      return this.$store.getters['clubs/list'] || []
    },
    currentTournament () {
      return this.$store.state.app.activeTournament
    }
  },
  watch: {
    clubs: {
      handler () {
        this.form.clubOptions = this.clubs.map((club) => ({
          value: club,
          label: club.name,
          id: club.id,
          tournamentId: club.tournamentId
        }))
      },
      immediate: true
    }
  },
  created () {
    this.load()
  },
  methods: {
    async load () {
      this.form.loading = true
      try {
        await this.$store.dispatch('clubs/load')
        this.form.clubOptions = this.clubs.map((club) => ({
          value: club,
          label: club.name,
          id: club.id,
          tournamentId: club.tournamentId
        }))
        this.form.error = false
      } catch (e) {
        console.error(e)
        this.form.error = true
      } finally {
        this.form.loading = false
      }
    },

    onClubChange () {
      const selectedClubId = this.form.selectedClub
      const selectedClub = this.clubs.find((club) => club.id === selectedClubId)

      if (selectedClub) {
        const tournamentId = selectedClub.tournamentId || this.currentTournament.id
        const path = `/tournaments/${tournamentId}/view/${selectedClubId}`
        this.$router.push(path)
      }
    }
  }
}
</script>
