import Vue from 'vue'
import App from './App'
import i18n from '@/i18n'
import moment from 'moment'
import store from './store'
import router from './router'
import Vuelidate from 'vuelidate'
import ElementUI from 'element-ui'
import localeRs from '@/common/lang'
import locale from 'element-ui/lib/locale/lang/en'
import VueTeleport from '@desislavsd/vue-teleport'
import config from './config'
import './common/filters'
import './common/components'
import '@/assets/scss/app.scss'

moment.locale('sr')

Vue.use(VueTeleport)
Vue.use(Vuelidate)
Vue.use(ElementUI, {
  locale: config.isWff ? locale : localeRs
})
Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
