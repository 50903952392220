<template>
  <panel :title="$tc('settings.category', 2)">
    <v-teleport to="header">
      <div class="flex mb-20">
        <el-button-group>
          <el-button
            :type="onlyArchived ? 'default' : 'primary'"
            size="medium"
            round
            @click="onlyArchived = false">
            {{ $tc('general.active') }}
          </el-button>
          <el-button
            :type="onlyArchived ? 'primary' : 'default'"
            icon="el-icon-takeaway-box"
            size="medium"
            round
            @click="onlyArchived = true">
            {{ $tc('general.archive') }}
          </el-button>
        </el-button-group>
      </div>
    </v-teleport>

    <template v-slot:actions>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="openManageCategory = true">
        {{ $tc('settings.create_category') }}
      </el-button>
    </template>

    <error-box v-if="error" :loading="loading" @reload="load()" />

    <category-table v-if="!error" :data="filteredCategories" @edit="openEdit($event)" can-edit />

    <category-manage
      v-if="openManageCategory"
      :category="editing"
      @add="close()"
      @edit="close()"
      @close="close()"
    />

  </panel>
</template>

<script>
import CategoryTable from '../category/CategoryTable.vue'
import CategoryManage from '../category/CategoryManage.vue'

export default {
  components: {
    CategoryTable,
    CategoryManage
  },

  data () {
    return {
      onlyArchived: false,
      editing: null,
      openManageCategory: false,

      error: false,
      loading: false
    }
  },

  computed: {
    categories () {
      return this.$store.getters['categories/list']
    },

    filteredCategories () {
      return this.categories.filter(category => {
        return this.onlyArchived ? category.isArchived : !category.isArchived
      })
    }
  },

  created () {
    this.$store.commit('app/set', {
      title: this.$tc('settings.category', 2),
      breadcrumbs: [
        {
          text: this.$tc('settings.settings'),
          to: { name: 'Settings' }
        },
        {
          text: this.$tc('settings.category', 2)
        }
      ]
    })
  },

  methods: {
    openEdit (category) {
      this.editing = category
      this.openManageCategory = true
    },

    async load () {
      this.loading = true
      try {
        await this.$store.dispatch('categories/load')
        this.error = false
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    close () {
      this.editing = null
      this.openManageCategory = false
    }
  }
}
</script>
