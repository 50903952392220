<template>
  <div>
    <panel title="Profile settings">
      <el-alert
        v-if="error"
        :title="$tc('general.ops')"
        :description="error"
        type="error"
        class="mb-15"
        :closable="false"
        show-icon>
      </el-alert>

      <el-form class="width small">
        <MainInput
          ref="note"
          v-model="form.name"
          :validator="$v.form.name"
          prefix-icon="el-icon-user"
          :label="$tc('form.name')" />

        <MainInput
          ref="note"
          v-model="form.email"
          :validator="$v.form.email"
          prefix-icon="el-icon-message"
          :label="$tc('form.email')" />

        <div class="center">
          <el-button
            type="primary"
            class="mt-15"
            :loading="loading"
            @click="save()">
            {{ $tc('general.save') }}
          </el-button>
        </div>
      </el-form>
    </panel>

    <input
      v-show="false"
      ref="file"
      type="file"
      accept="image/*"
      @change="onFileSelect($event)">
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

import MainInput from '@/components/forms/Input.vue'

export default {
  components: {
    MainInput
  },

  data () {
    return {
      form: {
        name: null,
        email: null
      },

      error: null,
      loading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  created () {
    this.form.name = this.user.name
    this.form.email = this.user.email
    this.preview = this.user.avatar

    this.$store.commit('app/set', {
      title: this.$tc('profile.profile_settings'),
      breadcrumbs: [
        {
          text: this.$tc('profile.my_profile')
        },
        {
          text: this.$tc('profile.profile_settings')
        }
      ]
    })
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('auth/update', this.form)
        this.$message.success(this.$tc('messages.success_updated_profile'))
        this.$v.$reset()
      } catch (error) {
        this.error = error.response?.data?.error || this.$defaultErrorMessage
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      name: { required },
      email: { required, email }
    }
  }
}
</script>
