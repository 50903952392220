<template>
  <div>
    <panel v-for="(entry, index) in data" :title="entry.label" :key="index" collapsable>
      <template v-slot:actions>
        <el-button
          icon="el-icon-printer"
          size="medium"
          class="print-dugme"
          circle
          @click="openPrintSchema(entry)" />
      </template>
      <div v-for="category in entry.items" :key="category.id">
        <label class="label">{{ category.name }}:</label>
        <div v-for="discipline in category.items" :key="discipline.id" class="discipline">
          <span>{{ discipline.name }} | </span>
          <el-button v-if="showSex(discipline, true)" icon="el-icon-printer" size="mini" @click="openPreview(category, discipline, entry.isTeam, true)">
            M ({{ preGetCompetitors(category.id, discipline.id, entry.isTeam ? teams : competitors, 'M', entry.isTeam) }})
          </el-button>
          <el-button v-if="showSex(discipline, false)" icon="el-icon-printer" size="mini" @click="openPreview(category, discipline, entry.isTeam, false)">
            Ž ({{ preGetCompetitors(category.id, discipline.id, entry.isTeam ? teams : competitors, 'F', entry.isTeam) }})
          </el-button>
        </div>
      </div>
    </panel>

    <el-dialog
      :title="category && discipline ? category.name + ' - ' + discipline.name : ''"
      :visible="!!modalData"
      width="90%"
      :key="isTeam"
      @close="closePreview()">
      <el-table :data="modalData" style="width: 100%">
        <el-table-column
          :label="$tc('general.n0')"
          width="50px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}.
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isTeam"
          :label="$tc('form.image')"
          width="100px">
          <template slot-scope="scope">
            <main-image :src="$file('avatars', scope.row.competitor.avatar)" style="width: 75px; height: 75px" />
          </template>
        </el-table-column>

        <el-table-column
          :label="$tc('form.name')">
          <template slot-scope="scope">
            {{ getCompetitorNames(scope.row, scope.row.assignedNumber)  }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isTeam"
          :label="$tc('form.belt')"
          width="120px">
          <template slot-scope="scope">
            {{ scope.row.competitor.belt | belt }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isTeam"
          :label="$tc('form.date_of_birth')"
          width="150px">
          <template slot-scope="scope">
            {{ scope.row.competitor.born | date }}
          </template>
        </el-table-column>

        <el-table-column
          v-if="isTeam"
          :label="$tc('general.type')"
          width="150px">
          <template slot-scope="scope">
            {{ scope.row.type | teamType }}
          </template>
        </el-table-column>

        <el-table-column
          :label="$tc('club.short_name')">
          <template slot-scope="scope">
            {{ scope.row.club.shortName }}, {{ scope.row.club.city }}
          </template>
        </el-table-column>
      </el-table>

      <template v-slot:footer>
        <el-button @click="closePreview()">{{ $tc('general.close') }}</el-button>
        <el-button type="primary" @click="openPrint(tournament.name, $refs.printTable ? $refs.printTable.$el : null)">{{ $tc('general.print') }}</el-button>
      </template>
    </el-dialog>

    <print-table-disciplines
      v-if="schemaData"
      v-show="false"
      ref="printSchema"
      :data="schemaData"
      :teams="teams"
      :competitors="competitors"
    />

    <tournament-print-modal
      v-if="modalData"
      v-show="false"
      ref="printTable"
      :isTeam="isTeam"
      :category="category"
      :discipline="discipline"
      :competitors="isTeam ? teams : competitors" />
  </div>
</template>

<script>
import openPrint from '@/common/print'
import { getCompetitors, getCompetitorNames } from './tournament-discipline'
import TournamentPrintModal from '@/components/tournaments/TournamentPrintModal'
import PrintTableDisciplines from '@/components/tournaments/PrintTableDisciplines'

export default {
  components: {
    TournamentPrintModal,
    PrintTableDisciplines
  },

  props: {
    tournament: {
      type: Object,
      required: true
    },

    competitors: {
      type: Array,
      required: true
    },

    teams: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      isMale: null,
      category: null,
      discipline: null,
      isTeam: false,
      modalVisible: false,
      schemaData: null,

      openPrint,
      getCompetitors,
      getCompetitorNames
    }
  },

  computed: {
    modalData () {
      if (!this.category || !this.discipline) return null
      const data = getCompetitors(this.category.id, this.discipline.id, this.isTeam ? this.teams : this.competitors) || []
      return this.getParsedData(data)
    },

    data () {
      return [
        {
          label: 'Pregled pojedinačnih disciplina i statistika prijave',
          items: this.getDisciplinesSortedByCategories(this.tournament.disciplines || [])
        },
        {
          label: 'Pregled ekipnih disciplina i statistika prijave',
          items: this.getDisciplinesSortedByCategories(this.tournament.teamDisciplines || []),
          isTeam: true
        }
      ]
    },

    allCategories () {
      return this.$store.state.categories.data
    }
  },

  methods: {
    preGetCompetitors (catId, disciplineId, competitors, filter, isTeam) {
      const data = this.getCompetitors(catId, disciplineId, competitors, !isTeam && filter) || []
      if (!isTeam) return data.length || 0

      const compKeys = ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour']
      const newData = JSON.parse(JSON.stringify(data))
      newData.forEach(d => {
        compKeys.forEach(key => {
          if ((filter === 'M' && !d[key]?.isMale) || (filter === 'F' && d[key]?.isMale)) {
            d[key] = null
          }
        })
      })

      return newData.filter(d => d.competitor || d.competitorTwo || d.competitorThree || d.competitorFour).length || 0
    },

    getParsedData (data) {
      data = JSON.parse(JSON.stringify(data))

      if (!this.isTeam) {
        return data.filter(c => this.isMale === c.competitor?.isMale)
      }

      ['competitor', 'competitorTwo', 'competitorThree', 'competitorFour'].forEach(key => {
        data.forEach(d => {
          if (this.isMale !== d[key]?.isMale) {
            d[key] = null
          }
        })
      })

      return data.filter(d => d.competitor || d.competitorTwo || d.competitorThree || d.competitorFour)
    },

    showSex (d, isMale) {
      return !d?.forSex || d.forSex === (isMale ? '1' : '2')
    },

    openPrintSchema (entry) {
      this.schemaData = entry
      this.openPrint(entry.label, this.$refs.printSchema ? this.$refs.printSchema.$el : null)
    },

    openPreview (cat, disc, isTeam, isMale) {
      this.isMale = isMale
      this.category = cat
      this.discipline = disc
      this.isTeam = isTeam
      this.modalVisible = true
    },

    closePreview () {
      this.category = null
      this.discipline = null
      this.isTeam = false
      this.modalVisible = false
    },

    getDisciplinesSortedByCategories (disciplines) {
      disciplines = disciplines.map(d => d.discipline)
      const categoryIdsObject = {}
      disciplines.forEach(d => {
        d.disciplineCategories.forEach(cat => {
          categoryIdsObject[cat.categoryId] = true
        })
      })

      const tournamentCategories = this.allCategories.filter(category => Object.keys(categoryIdsObject).includes(category.id + '')) || []

      return tournamentCategories.map(category => ({
        ...category,
        items: this.getDisciplineFromCategory(disciplines, category.id)
      }))
    },

    getDisciplineFromCategory (disciplines, categoryId) {
      const tournamentDisciplines = disciplines.map(tournamentDiscipline => ({
        ...tournamentDiscipline,
        categoryIds: tournamentDiscipline.disciplineCategories.map(category => category.categoryId)
      }))

      return tournamentDisciplines.filter(d => d.categoryIds.includes(categoryId))
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    font-weight: bold;
    color: #000;
  }

  .discipline {
    padding-bottom: 5px;
    span {
      margin-right: 5px;
    }
  }
</style>
