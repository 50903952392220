<template>
  <div v-if="user" class="page-wrapper">
    <sidebar />

    <main>
      <PageHeader />

      <router-view />
    </main>
  </div>
</template>

<script>
import Sidebar from '@/components/ui/Sidebar'
import PageHeader from '@/components/ui/PageHeader'

export default {
  components: {
    Sidebar,
    PageHeader
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    padding-left: 380px;
    padding-right: 30px;
  }
  @media (max-width: 1150px) {
    main {
      padding: 80px 20px 0 20px;
    }

  }
</style>
