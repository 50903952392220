// import Dashboard from '../views/Dashboard'
import { features } from '@/config'

import Layout from '../views/Layout'
import Login from '../views/auth/Login'
import Clubs from '../views/clubs/Clubs'
import Users from '../views/users/Users'
import SimpleView from '../views/SimpleView'
import Register from '../views/auth/Register'
import ClubView from '../views/clubs/ClubView'
import NotFound from '../views/other/NotFound'
import ProfileView from '../views/profile/View'
import AppSettings from '../views/settings/AppSettings'
import ForgotPassword from '../views/auth/ForgotPassword'
import SettingsView from '../views/settings/SettingsView'
import ManageProfile from '../views/profile/ManageProfile'
import Tournaments from '../views/tournaments/Tournaments'
import ResetPassword from '../views/auth/ResetPassword'
import ChangePassword from '../views/profile/ChangePassword'
import TournamentView from '../views/tournaments/TournamentView'
import TournamentClubView from '../views/tournaments/TournamentClubView'
import CategorySettings from '../views/settings/category/CategorySettings'
import DisciplineSettings from '../views/settings/discipline/DisciplineSettings'
import RegistrationFeeSettings from '../views/settings/registration-fee/RegistrationFeeSettings'

export default [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: { name: 'MyClub' },
    meta: {
      auth: true
    },
    children: [
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   component: Dashboard
      // },
      {
        path: 'my-club',
        name: 'MyClub',
        component: ClubView
      },
      {
        path: 'clubs',
        component: SimpleView,
        name: 'ClubsMain',
        redirect: features.isWff ? { name: 'MyClub' } : { name: 'Clubs' },
        children: [
          {
            path: '/',
            name: 'Clubs',
            component: Clubs
          },
          {
            path: ':id',
            name: 'ClubView',
            component: ClubView
          }
        ]
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'tournament',
        name: 'ActiveTournament',
        component: TournamentView
      },
      {
        path: 'tournament/view/:clubId',
        name: 'ActiveTournamentClubView',
        meta: { isAdmin: true },
        component: TournamentClubView
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { isAdmin: true },
        redirect: { name: 'AppSettings' },
        component: SettingsView,
        children: [
          {
            path: '/',
            name: 'AppSettings',
            component: AppSettings
          },
          {
            path: 'categories',
            name: 'CategorySettings',
            component: CategorySettings
          },
          {
            path: 'disciplines',
            name: 'DisciplineSettings',
            component: DisciplineSettings
          },
          {
            path: 'registration-fee',
            name: 'RegistrationFeeSettings',
            redirect: !features.registration_fee && { name: 'AppSettings' },
            component: RegistrationFeeSettings
          }
        ]
      },
      {
        path: 'tournaments',
        meta: { isAdmin: true },
        component: SimpleView,
        redirect: { name: 'Tournaments' },
        children: [
          {
            path: '/',
            name: 'Tournaments',
            component: Tournaments
          },
          {
            path: ':id',
            name: 'TournamentView',
            component: TournamentView
          },
          {
            path: ':id/view/:clubId',
            name: 'TournamentClubView',
            component: TournamentClubView
          }
        ]
      },
      {
        path: 'profile',
        component: ProfileView,
        name: 'ManageProfileParent',
        redirect: { name: 'ManageProfile' },
        children: [
          {
            path: '/',
            name: 'ManageProfile',
            component: ManageProfile
          },
          {
            path: 'change-password',
            name: 'ChangePassword',
            component: ChangePassword
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      not_logged: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      not_logged: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      not_logged: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      not_logged: true
    }
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]
