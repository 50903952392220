<template>
  <panel ref="panel" :title="`${$tc('club.coach', 2)} (${coaches.length})`" collapsable>
    <template v-slot:actions>
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        round
        @click="$refs.panel.expanded = true; openManageCoaches = true">
        {{ $tc('auth.add_coach') }}
      </el-button>

      <el-tooltip v-if="coaches.length" :content="$tc('general.print')" placement="top">
        <el-button
          icon="el-icon-printer"
          size="medium"
          circle
          @click="openPrint($tc('club.coach', 2), $refs.printTable ? $refs.printTable.$el : null)" />
      </el-tooltip>
    </template>

    <coaches-table
      :data="coaches"
      :can-edit="canEdit"
      :custom-logic="customLogic"
      :show-club-name="showClubName"
      @edit="openEdit($event)"
      @custom-delete="$emit('custom-delete', $event)"
      @delete="$emit('delete', $event)" />

    <print-coaches-table ref="printTable" v-show="false" :data="coaches" :show-club-name="showClubName" />

    <manage-coach
      v-if="openManageCoaches"
      :club="club"
      :coach="editing"
      :custom-logic="customLogic"
      @edit="onEdit($event)"
      @custom-add="$emit('custom-add', $event); close()"
      @add="$emit('add', $event); close()"
      @close="close()" />
  </panel>
</template>

<script>
import openPrint from '@/common/print'

import ManageCoach from './ManageCoach'
import CoachesTable from './CoachesTable'
import PrintCoachesTable from './PrintCoachesTable'

export default {
  components: {
    ManageCoach,
    CoachesTable,
    PrintCoachesTable
  },

  props: {
    club: {
      type: Object,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    },

    canEdit: {
      type: Boolean,
      default: false
    },

    coaches: {
      type: Array,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      openPrint,

      editing: null,
      openManageCoaches: false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },

  methods: {
    onEdit (coach) {
      const club = this.$store.getters['clubs/get'](coach.clubId)
      if (club) {
        this.$store.commit('clubs/setSingle', this.getClubUpdateCoach(club, coach))
      }

      if (this.user.club?.id === coach.clubId) {
        this.$store.commit('auth/updateUsersClub', this.getClubUpdateCoach(this.user.club, coach))
      }

      this.close()
    },

    getClubUpdateCoach (club, member) {
      const idx = club.coaches?.findIndex(m => m.id === member.id)
      const newData = [...club.coaches]
      newData[idx] = member
      club.coaches = newData
      return club
    },

    openEdit (coach) {
      this.editing = coach
      this.openManageCoaches = true
    },

    close () {
      this.editing = null
      this.openManageCoaches = false
    }
  }
}
</script>
