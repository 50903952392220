<template>
  <main-drawer :title="isEdit ? $tc('auth.edit_coach') : $tc('auth.add_coach')" @close="$emit('close')">
    <main-input v-model="form.firstName" :label="$tc('form.first_name')" :validator="$v.form.firstName" />
    <main-input v-model="form.lastName" :label="$tc('form.last_name')" :validator="$v.form.lastName" />
    <main-upload-input v-model="image" :label="$tc('form.image')" />

    <template v-if="$config.isWff">
      <main-input v-model="form.noCertificateWff" :label="$tc('form.no_certificate_wff')" :validator="$v.form.noCertificateWff" />
      <main-input v-model="form.noFudokanPassport" :label="$tc('form.no_fudokan_passport')" :validator="$v.form.noFudokanPassport" />
    </template>

    <template v-slot:footer>
      <div class="flex-buttons">
        <el-button @click="$emit('close')">{{ $tc('general.cancel') }}</el-button>
        <el-button type="primary" @click="save()">{{ isEdit ? $tc('general.edit') : $tc('general.add') }}</el-button>
      </div>
    </template>
  </main-drawer>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    club: {
      type: Object,
      default: null
    },

    coach: {
      type: Object,
      default: null
    },

    customLogic: {
      type: Boolean,
      default: null
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        noCertificateWff: null,
        noFudokanPassport: null
      },
      image: null
    }
  },

  computed: {
    isEdit () {
      return !!this.coach
    }
  },

  created () {
    if (this.isEdit) {
      this.form = {
        firstName: this.coach.firstName,
        lastName: this.coach.lastName,
        noCertificateWff: this.coach.noCertificateWff,
        noFudokanPassport: this.coach.noFudokanPassport
      }
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      if (this.customLogic) return this.$emit('custom-add', this.form)

      this.loading = true
      try {
        let coach
        if (this.isEdit) {
          coach = await this.$store.dispatch('coaches/update', {
            id: this.coach.id,
            payload: this.form
          })
        } else {
          coach = await this.$store.dispatch('coaches/create', {
            clubId: this.club.id,
            ...this.form
          })
        }

        if (this.image) {
          coach = await this.$store.dispatch('coaches/upload', {
            id: coach.id,
            file: this.image
          })
        }

        this.$message.success(this.isEdit ? this.$tc('messages.success_updated_coach') : this.$tc('messages.success_created_coach'))
        this.$emit(this.isEdit ? 'edit' : 'add', coach)
      } catch (error) {
        console.error(error)
        errorHandler(error)
      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    return {
      form: {
        firstName: { required },
        lastName: { required }
      }
    }
  }
}
</script>
