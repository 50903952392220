<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>{{ $tc('general.n0') }}</th>
          <th>{{ $tc('form.image') }}</th>
          <th>{{ $tc('form.name') }}</th>
          <th>{{ $tc('form.date_of_birth') }}</th>
          <th>{{ $tc('form.gender') }}</th>
          <th>{{ $tc('form.belt') }}</th>
          <th v-if="showClubName">{{ $tc('general.club') }}</th>
          <th>{{ $tc('settings.discipline') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(registration, index) in data" :key="registration.id">
          <td>{{ index + 1 }}.</td>
          <td style="width: 80px">
            <div class="image">
              <img :src="$file('avatars', registration.competitor.avatar)" style="width: 75px; height: 75px; object-fit: cover;" class="image" />
            </div>
          </td>
          <td>{{ registration.assignedNumber ? `#${registration.assignedNumber} ` : ''}}{{ registration.competitor.firstName }} {{ registration.competitor.lastName }}</td>
          <td>{{ registration.competitor.born | date }}</td>
          <td>{{ registration.competitor.isMale | sex }}</td>
          <td>{{ registration.competitor.belt | belt }}</td>
          <td v-if="showClubName">{{ registration.club.shortName }}</td>
          <td>
            <div
              v-for="(item, index) in getDisciplinesDataList(registration.categories)"
              :key="index"
              class="data-list-item">
              <span v-if="item.label" class="label">{{ item.label }}</span>
              <span v-if="item.text" class="text">{{ item.text }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: null
    },

    showClubName: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getDisciplinesDataList (categories) {
      return categories?.reduce((acc, item) => {
        acc.push({ label: item.category?.name })
        const discplines = item.disciplines?.map(disc => ({ text: disc.discipline.name }))
        acc.push(...discplines)
        return acc
      }, [])
    }
  }
}
</script>
