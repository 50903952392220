export default {
  general: {
    home: 'Početna',
    print: 'Štampaj',
    informations: 'Informacije',
    dashboard: 'Početna',
    other: 'Ostalo',
    club: 'Sportsko udruženje | Sportska udruženja',
    registration_fee: 'Kotizacija',
    registration_fee_discount: 'Popust kotizacije',
    male: 'Muški',
    female: 'Ženski',
    mixed: 'Muško/Ženski',
    save: 'Sačuvaj',
    both: 'Oba',
    men: 'Muškarci',
    women: 'Žene',
    edit: 'Izmeni',
    add: 'Dodaj',
    create: 'Napravi',
    actions: 'Akcije',
    next: 'Dalje',
    ops: 'Uuups!',
    prev_step: 'Prethodni korak',
    page_doesnt_exists: 'Strana kojoj pokusašavaš da pristupiš ne postoji.',
    back_to_home: 'Nazad na početnu',
    type: 'Tip',
    cancel: 'Otkazi',
    function: 'Funkcija',
    documents: 'Dokumenta',
    images: 'Slike',
    n0: 'Rb.',
    cover_image: 'Naslovna slika',
    yes: 'Da',
    no: 'Ne',
    change: 'Izmena',
    date: 'Datum',
    close: 'Zatvori',
    active: 'Aktivni | Aktiviraj',
    archive: 'Arhiva | Arhiviraj',
    unarchive: 'Aktiviraj',
    delete: 'Obriši',
    alphabetically: 'A-Z',
    latest_first: 'Poslednji pri vrhu',
    oldest_first: 'Stariji pri vrhu',
    by_club: 'Po sportskom udruženju',
    order_by: 'Redosled'
  },

  menu: {
    my_club: 'Moje Sportsko Udruženje',
    open_tournament: 'Otvoreni turnir',
    last_tournament: 'Poslednji turnir',
    admin_panel: 'Admin Panel',
    users: 'Korisnici'
  },

  auth: {
    login: 'Prijava',
    log_out: 'Odjavi se',
    register: 'Registracija',
    no_account_register: 'Nemate nalog? Registrujte se',
    have_account_login: 'Imate nalog? Prijavite se',
    sign_in: 'Prijavi se',
    sign_up: 'Registruj se',
    profile_information: 'Informacije Profila',
    club_info: 'Informacije Sportske Organizacije',
    forgot_password: 'Zaboravili ste lozinku?',
    reset_password: 'Resetuj lozinku',
    add_coach: 'Dodaj trenera',
    add_referee: 'Dodaj sudiju',
    edit_coach: 'Izmeni trenera',
    edit_referee: 'Izmeni sudiju',
    add_member: 'Dodaj člana',
    edit_member: 'Izmeni člana'
  },

  form: {
    name: 'Ime',
    first_name: 'Ime',
    last_name: 'Prezime',
    city: 'Grad',
    address: 'Adresa',
    username: 'Korisničko ime',
    price: 'Cena',
    website: 'Web sajt',
    contact_person: 'Kontakt osoba',
    discount: 'Popust',
    description: 'Opis',
    mobile: 'Mobilni',
    email: 'Email',
    enter_email: 'Unesite email adresu',
    password: 'Lozinka',
    repeat_password: 'Ponovi lozinku',
    enter_password: 'Unesite lozinku',
    image: 'Slika',
    date_of_birth: 'Datum rođenja',
    gender: 'Pol',
    belt: 'Pojas',
    day: 'Dan'
  },

  tournament: {
    teams: 'Ekipe',
    competitors: 'Takmičar | Takmičari',
    tournament_name: 'Naziv turnira',
    tournament: 'Turnir | Turniri',
    create_tournament: 'Napravi turnir',
    registered_competitors: 'Prijavljeni takmičari',
    all_registered_competitors: 'Svi prijavljeni takmičari',
    all_registered_teams: 'Sve prijavljene ekipe',
    my_registered_competitors: 'Moji prijavljeni takmičari',
    my_registered_teams: 'Moje prijavljene ekipe',
    close_applications: 'Zatvori prijave',
    edit_tournament: 'Izmeni turnir',
    edit_club_name: 'Izmeni sportsko udruzenje',
    registration_start_date: 'Početak prijava',
    registration_end_date: 'Završetak prijava',
    single_disciplines: 'Pojedinačne discipline',
    team_disciplines: 'Ekipne discipline',
    number_of_registered_competitors: 'Broj prijavljenih takmičara',
    number_of_registered_disciplines:
      'Broj prijavljenih pojedinačnih disciplina',
    number_of_registered_team_disciplines:
      'Broj prijavljenih ekipnih disciplina',
    number_of_registered_clubs: 'Broj prijavljenih sportskih udruženja',
    ask_delete_tournament: 'Da li si siguran da želiš da obrišeš ovaj turnir',
    register_competitor: 'Prijavi takmičara',
    edit_competitor: 'Izmeni takmičara',
    register_team: 'Prijavi ekipu',
    assign_numbers: 'Dodela brojeva',
    change_history: 'Istorija izmena',
    add_team: 'Dodaj ekipu',
    edit_team: 'Izmeni ekipu'
  },

  club: {
    club_name: 'Naziv sportskog udruženja',
    short_name: 'Skraćeni Naziv Sportske Organizacije',
    member: 'Član sportskog udruženja | Članovi sportskog udruženja',
    my_federation_information: 'Informacije sportskog udruženja',
    coach: 'Trener | Treneri',
    official: 'Zvaničnik | Zvaničnici',
    referee: 'Sudija | Sudije',
    referee_rang: 'Rang sudije',
    add_official: 'Dodaj zvaničnika',
    edit_official: 'Izmeni zvaničnika',
    add_member: 'Dodaj člana',
    ask_delete_club: 'Da li si siguran da želiš da ukloniš ovo udruženje',
    ask_delete_official: 'Da li si siguran da želiš da ukloniš zvaničnika',
    registration_fee_free: 'Oslobođen/a kotizacije',
    must_pay_registration_fee: 'Obavezna kotizacija'
  },

  profile: {
    my_profile: 'Moj profil',
    profile_settings: 'Podešavanja profila',
    change_password: 'Promeni lozinku',
    current_password: 'Trenutna lozinka',
    new_password: 'Nova lozinka'
  },

  settings: {
    settings: 'Podešavanja',
    category: 'Kategorija | Kategorije',
    discipline: 'Disciplina | Discipline',
    allowed_sexes: 'Dozvoljeni polovi',
    general_settings: 'Generalna podešavanja',
    website_name: 'Naziv web sajta',
    website_description: 'Opis web sajta',
    logo: 'Logo',
    create_category: 'Napravi kategoriju',
    create_discipline: 'Napravi disciplinu',
    edit_discipline: 'Izmeni disciplinu',
    edit_category: 'Izmeni kategoriju',
    one_discipline: 'Jedna disciplina',
    two_disciplines: 'Dve discipline',
    three_or_more_disciplines: 'Tri ili više disciplina',
    team_disciplines: 'Ekipne discipline',
    discipline_always_chargeable: 'Ova disciplina se uvek naplaćuje',
    discipline_not_chargeable: 'Ova disciplina se ne naplaćuje',
    category_not_chargeable: 'Ova kategorija se ne naplaćuje'
  },

  messages: {
    success_sign_in: 'Uspešno ste se prijavili.',
    error_email_password_combination: 'Pogresna email adresa ili lozinka.',
    success_register: 'Uspešno ste se registrovali. Možete se prijaviti',
    settings_successfully_updated: 'Podešavanja uspešno sačuvana.',
    success_remove_discipline: 'Disciplina je obrisana uspešno.',
    success_remove_category: 'Kategorija je obrisana uspešno.',
    ask_delete_official: 'Da li si siguran da želiš da ukloniš zvaničnika',
    ask_delete_tournament: 'Da li si siguran da želiš da obrišeš ovaj turnir?',
    ask_remove_discipline:
      'Da li ste sigurni da želite da obrišete ovu disciplinu',
    ask_remove_category:
      'Da li ste sigurni da želite da obrišete ovu kategoriju',
    ask_remove_coach: 'Da li ste sigurni da želite da obrišete trenera',
    ask_remove_team: 'Da li ste sigurni da želite da obrišete ovaj savez',
    ask_remove_referee: 'Da li ste sigurni da želite da obrišete sudiju',
    ask_assign_numbers:
      'Da li ste sigurni da želite da dodelite brojeve takmičarima?',
    success_updated_referee: 'Uspešno ste izmenili sudiju.',
    success_created_referee: 'Uspešno ste dodali sudiju.',
    success_updated_official: 'Uspešno ste izmenili zvaničnika.',
    success_created_official: 'Uspešno ste dodali zvaničnika.',
    success_updated_discipline: 'Disciplinaje je izmenjena uspešno.',
    success_created_discipline: 'Disciplina je napravljena uspešno.',
    success_updated_category: 'Kategorija je izmenjena uspešno.',
    success_created_category: 'Kategorija je napravljena uspešno.',
    success_updated_profile: 'Profil je izmenjen uspešno.',
    success_changed_password: 'Lozinka uspešno promenjena.',
    success_deleted_tournament: 'Uspešno ste uklonili turnir.',
    success_deleted_team: 'Uspešno ste uklonili ekipu.',
    success_deleted_referee: 'Uspešno ste uklonili sudiju.',
    success_deleted_club: 'Uspešno ste uklonili sportsko udruženje.',
    success_deleted_official: 'Uspešno ste uklonili zvaničnika.',
    success_deleted_coach: 'Uspešno ste uklonili sudiju.',
    success_updated_coach: 'Uspešno ste izmenili trenera.',
    success_created_coach: 'Uspešno ste dodali trenera.',
    success_forgot_password_request:
      'Uspešno ste poslali zahtev za resetovanje lozinke. Proverite vaš email.',
    error_unknown: 'Došlo je do greške. Molimo vas pokušajte ponovo.',
    error_unknown_account_email: 'Ne postoji nalog sa unetom email adresom.',
    success_reset_password:
      'Uspešno ste resetovali lozinku. Možete se prijaviti.',
    success_assign_numbers: 'Uspešno ste dodelili brojeve takmičarima.',
    error_enter_at_least_one_discipline: 'Morate uneti barem jednu disciplinu.',
    success_updated_competitor: 'Uspešno ste izmenili takmičara.',
    success_registered_competitor: 'Uspešno ste prijavili takmičara.',
    ask_remove_competitor: 'Da li si siguran da želiš da ukloniš takmičara',
    success_remove_competitor: 'Uspešno ste uklonili takmičara.',
    error_number_of_selected_competitors:
      'Broj izabranih takmičara mora biti 2, 3 ili 4.',
    error_select_at_least_one_discipline:
      'Morate uneti barem jednu disciplinu.',
    success_updated_team: 'Uspešno ste izmenili tim.',
    success_registered_team: 'Uspešno ste prijavili tim.',
    success_updated_member: 'Uspešno ste izmenili člana.',
    success_created_member: 'Uspešno ste dodali člana.',
    ask_archive_tournament: 'Da li si siguran da želiš da arhiviraš ovaj turnir',
    ask_unarchive_tournament: 'Da li si siguran da želiš da aktiviraš ovaj turnir',
    ask_archive_category: 'Da li si siguran da želiš da arhiviraš ovu kategoriju',
    ask_unarchive_category: 'Da li si siguran da želiš da aktiviraš ovu kategoriju',
    ask_archive_discipline: 'Da li si siguran da želiš da arhiviraš ovu disciplinu',
    ask_unarchive_discipline: 'Da li si siguran da želiš da aktiviraš ovu disciplinu',
    success_archived_tournament: 'Uspešno si arhivirao turnir.',
    success_unarchived_tournament: 'Uspešno si aktivirao turnir.',
    success_archived_category: 'Uspešno si arhivirao kategoriju.',
    success_unarchived_category: 'Uspešno si aktivirao kategoriju.',
    success_archived_discipline: 'Uspešno si arhivirao disciplinu.',
    success_unarchived_discipline: 'Uspešno si aktivirao disciplinu.'
  },

  validation: {
    required: 'Ovo polje je obavezno.',
    isTruthy: 'Ovo polje je obavezno.',
    email: 'Unesite ispravnu email adresu.',
    integer: 'Polje mora biti broj.',
    url: 'Unesite ispravnu URL adresu.',
    minLength: 'Broj karaktera mora biti veći.',
    sameAsPassword: 'Lozinke se ne poklapaju.'
  },

  changes: {
    REGISTER_COMPETITOR: 'je prijavio takmičara',
    COMPETITOR_UPDATE: 'je izmenio takmičarsku prijavu',
    COMPETITOR_DELETE: 'je obrisao takmičara',
    REGISTER_TEAM: 'je prijavio ekipu',
    TEAM_UPDATE: 'je izmenio ekipu',
    TEAM_DELETE: 'je obrisao ekipu'
  },

  referee_rangs: {
    NATIONAL_A: 'Nacionalni A',
    NATIONAL_B: 'Nacionalni B',
    NATIONAL_C: 'Nacionalni C',
    NATIONAL_D: 'Nacionalni D',
    INTEARNATIONAL_A: 'Internacionalni A',
    INTEARNATIONAL_B: 'Internacionalni B',
    INTEARNATIONAL_C: 'Internacionalni C',
    INTEARNATIONAL_D: 'Internacionalni D',
    TRAINEE: 'Pripravnik',
    ZAPISNICAR: 'Zapisnicar',
    OTHER: 'Drugo'
  }
}
