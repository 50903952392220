const tableHtml = (title, html) => {
  const titleHTML = title ? `<h1>${title}</h1>` : ''
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title || process.env.VUE_APP_TITLE}</title>
      <style>
        body {
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
        }
        h1 {
          text-align: center;
          margin: 0 0 30px 0;
        }
        table {
          width: 100%;
          border: none;
          border-collapse: collapse;
          border-top: 1px solid #d1d1d1;
        }
        table thead tr {
          border-bottom: 1px solid #d1d1d1;
        }
        table thead tr th {
          text-align: left;
          padding: 7px 12px;
        }
        table tbody tr {
          border-bottom: 1px solid #d1d1d1;

        }
        table tbody tr td {
          text-align: left;
          padding: 7px 12px;
        }


        .data-list-item {
          line-height: 1.5;
        }

        .data-list-item .label {
          fonts-size: 10px;
          font-weight: 500;
          color: #000;
        }

        .data-list-item .text {
          fonts-size: 12px;
          color: #444;
        }
      </style>
    </head>
    <body>
      ${titleHTML}
      ${html}
    </body>
    </html>
  `
}

const openPrint = (title, el) => {
  if (!el) return
  const newWin = window.open('')
  newWin.document.write(tableHtml(title, el.innerHTML))
  newWin.print()
  newWin.close()
}

export default openPrint
