<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <el-date-picker
      v-if="!showAsText"
      v-model="val"
      v-bind="options"
      :type="type"
      :style="{ width: '100%' }"
      :disabled="disabled"
      :placeholder="placeholder || 'Izaberi datum'"
      :picker-options="pickerOptions"
      :value-format="valueFormat"
      range-separator="To"
      start-placeholder="Početni datum"
      end-placeholder="Završni datum" />
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'date'
    },

    pickerOptions: {
      type: Object,
      default: null
    }
  },

  computed: {
    valueFormat () {
      return this.type === 'datetime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>
