<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Kategorija</th>
          <th>Disciplina</th>
          <th>M</th>
          <th>Ž</th>
        </tr>
      </thead>
      <tbody v-for="category in data.items" :key="category.id">
        <tr v-for="discipline, index in category.items" :key="discipline.id">
          <td v-if="index === 0" :rowspan="category.items.length">{{ category.name }}</td>
          <td>{{ discipline.name }}</td>
          <td>{{ getCompetitors(category.id, discipline.id, data.isTeam ? teams : competitors, 'M').length || 0 }}</td>
          <td>{{ getCompetitors(category.id, discipline.id, data.isTeam ? teams : competitors, 'F').length || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getCompetitors } from './tournament-discipline'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    teams: {
      type: Array,
      required: true
    },

    competitors: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      getCompetitors
    }
  }
}
</script>
