<template>
  <div class="register-user-info">
    <img
      src="@/assets/images/login.svg"
      class="image">

    <panel class="panel">
      <h1>{{ $tc('auth.register') }}</h1>

      <el-form
        :model="form"
        label-width="0">
        <main-input v-model="form.name" :label="$tc('form.username')" :validator="$v.form.name" />
        <main-input v-model="form.email" :label="$tc('form.email')" :validator="$v.form.email" />
        <main-input v-model="form.password" type="password" :label="$tc('form.password')" :validator="$v.form.password" show-password />
      </el-form>

      <el-button
        class="button"
        type="primary"
        round
        @click="next()">
        {{ $tc('general.next') }}
      </el-button>

      <router-link
        :to="{ name: 'Login' }"
        class="link">
        {{ $tc('auth.have_account_login') }}
      </router-link>
    </panel>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        name: null,
        email: null,
        password: null
      },

      loading: false
    }
  },

  methods: {
    async next () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('auth/validate', this.form)
        this.$emit('next', this.form)
      } catch (e) {
        console.error(e)
        errorHandler(e)
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  }
}
</script>

<style lang="scss" scoped>
  .register-user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 500px;
      margin-right: 150px;
    }
    .panel {
      width: 500px;
      padding: 30px 50px;
      .button {
        margin-top: 30px;
        width: 100%;
        height: 50px;
        border-radius: 9999px;
      }
      .link {
        display: block;
        text-align: center;
        margin-top: 20px;
        font-size: 17px;
        color: $dark;
      }
    }
  }

  @media (max-width: 1050px) {
    .register-user-info .image{
      margin: 0;
    }
     .panel {
        padding-right: 0 !important;
      }
  }
</style>
