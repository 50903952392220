import Vue from 'vue'
import VueI18n from 'vue-i18n'

import stkf from './stkf'
import wff from './wff'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: process.env.VUE_APP_NAME || 'stkf',
  messages: {
    stkf,
    wff
  }
})

export default i18n
