import { Message } from 'element-ui'

export default (error) => {
  let text = error?.response?.data?.message || error?.response?.data?.error || 'Uuups. Došlo je do greške.'
  if (error?.response?.data?.errors) {
    text = Object.values(error.response.data.errors).join('\r\n')
  }

  Message.error(text)
}
