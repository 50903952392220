<template>
  <div class="data-list">
    <data-list-item
      v-for="(item, index) in filteredList"
      :key="index"
      :item="item"
      :emptyText="emptyText" />
  </div>
</template>

<script>
import DataListItem from './DataListItem'

export default {
  components: { DataListItem },

  props: {
    list: {
      type: Array,
      required: true
    },

    emptyText: {
      type: String,
      default: null
    }
  },

  computed: {
    filteredList () {
      return this.list.filter(item => item.condition !== false)
    }
  }
}
</script>
